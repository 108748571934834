const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const GENERIC_ACTION = 'GENERIC_ACTION'

const GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS = 'GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS';

const GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_SUCCESS = 'GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_SUCCESS';
const GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_FAILURE = 'GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_FAILURE';
const GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_RESET = 'GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_RESET';

const GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_MORE = 'GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_MORE';
const GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_MORE_SUCCESS = 'GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_MORE_SUCCESS'

export const getPurpleLabDataForSiteEffectivenessAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS,
      url: `${process.env.apiUrl}v0/purplelab/getPurpleLabDataForSite`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getPurpleLabDataForSiteEffectivenessResetAction = () => ({
  type: GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_RESET,
})

export const getPurpleLabDataForSiteEffectivenessMoreAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_MORE,
      url: `${process.env.apiUrl}v0/purplelab/getPurpleLabDataForSite`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}
export function getPurpleLabDataForSiteEffectivenessReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }

    case GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      };
    }
    case GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }

    case GET_PURPLE_LAB_DATA_FOR_CENTER_EFFECTIVENESS_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    default:
      return state;
  }
}
