import React from 'react';
import { Button, Select, Input } from 'antd';

export default function RenderFilterEmpByInsurance(props) {
  const {
    data, idx, setData, handleAdd, handleMinus,
  } = props;

  const handleChange = (e, type) => {
    setData((old) => {
      const temp = { ...old };
      let value = '';
      if (type === 'value') value = e.target.value;
      else value = e;
      temp.employment_by_insurance[idx][type] = value;
      return temp;
    });
  };

  const getValue = (type) => {
    return data.employment_by_insurance[idx][type];
  };

  const onSwitchChange = (index) => {
    setData((old) => {
      const temp = JSON.parse(JSON.stringify(old));
      const oldOperator = temp.employment_by_insurance[index].operator;
      if (oldOperator === 'AND') {
        temp.employment_by_insurance[index].operator = 'OR';
      } else {
        temp.employment_by_insurance[index].operator = 'AND';
      }
      return temp;
    });
  };
  return (
    <div className="race-inner-wrap" key={idx}>
      {getValue('operator') && (
        <div className="value-wrap after-plus-content">
          <Button onClick={() => onSwitchChange(idx)}>
            {getValue('operator')}
          </Button>
        </div>
      )}
      <div className="group-input-fields">
        <div className="value-wrap">
          <Select
            style={{
              width: 120,
            }}
            placeholder="Please select"
            options={[
              {
                value: 'Labor Force',
                label: 'Labor Force',
              },
              {
                value: 'Not in Labor Force',
                label: 'Not In Labor Force',
              },
            ]}
            value={getValue('field')}
            onChange={(e) => handleChange(e, 'field')}
          />
        </div>
        <div className="value-wrap">
          <Select
            style={{
              width: 120,
            }}
            placeholder="Please select"
            options={[
              {
                value: 'Employed',
                label: 'Employed',
              },
              {
                value: 'Unemployed',
                label: 'Unemployed',
              },
            ]}
            value={getValue('type')}
            onChange={(e) => handleChange(e, 'type')}
          />
        </div>
        <div className="value-wrap">
          <Select
            style={{
              width: 120,
            }}
            placeholder="Please select"
            options={[
              {
                value: 'Insured',
                label: 'Insured',
              },
              {
                value: 'Uninsured',
                label: 'Uninsured',
              },
            ]}
            value={getValue('label')}
            onChange={(e) => handleChange(e, 'label')}
          />
        </div>
        <div className="value-wrap">
          <Select
            style={{
              width: 120,
            }}
            placeholder="Please select"
            options={[
              {
                value: 'lt',
                label: 'is less than',
              },
              {
                value: 'gt',
                label: 'is greater than',
              },
            ]}
            value={getValue('compare')}
            onChange={(e) => handleChange(e, 'compare')}
          />
        </div>
        <div className="value-wrap">
          <Input
            placeholder="Population Range"
            onChange={(e) => handleChange(e, 'value')}
          />
        </div>
        <div className="value-wrap">
          <div style={{ display: 'flex' }}>
            <Button className="plus-btn" onClick={() => handleAdd(idx, 'employment_by_insurance')}>
              +
            </Button>
            {data.employment_by_insurance.length > 1 && (
            <Button
              className="plus-btn"
              onClick={() => handleMinus(idx, 'employment_by_insurance')}
            >
              -
            </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
