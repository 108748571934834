import React from 'react';
import { Button, Select, Input } from 'antd';

export default function RenderFilterStudies(props) {
  const {
    data, idx, setData, handleAdd, handleMinus,
  } = props;

  const handleChange = (e, type) => {
    setData((old) => {
      const temp = { ...old };
      let value = '';
      if (type === 'value') value = e.target.value;
      else value = e;
      temp.studies[idx][type] = value;
      return temp;
    });
  };
  const getValue = (type) => {
    return data.studies[idx][type];
  };
  const onSwitchChange = (index) => {
    setData((old) => {
      const temp = JSON.parse(JSON.stringify(old));
      const oldOperator = temp.studies[index].operator;
      if (oldOperator === 'AND') {
        temp.studies[index].operator = 'OR';
      } else {
        temp.studies[index].operator = 'AND';
      }
      return temp;
    });
  };
  return (
    <div className="race-inner-wrap" key={idx}>
      {getValue('operator') && (
        <div className="value-wrap after-plus-content">
          <Button onClick={() => onSwitchChange(idx)}>
            {getValue('operator')}
          </Button>
        </div>
      )}
      <div className="group-input-fields">
        <div className="value-wrap">
          <Select
            style={{
              width: 120,
            }}
            placeholder="Please select"
            options={[
              {
                value: 'industry_studies',
                label: 'Industrial Studies',
              },
              {
                value: 'parexel_studies',
                label: 'Parexel Studies',
              },
            ]}
            value={getValue('field')}
            onChange={(e) => handleChange(e, 'field')}
          />
        </div>
        <div className="value-wrap">
          <Select
            style={{
              width: 120,
            }}
            placeholder="Please select"
            options={[
              {
                value: 'lt',
                label: 'is less than',
              },
              {
                value: 'gt',
                label: 'is greater than',
              },
              {
                value: 'eq',
                label: 'is equal to',
              },
            ]}
            value={getValue('compare')}
            onChange={(e) => handleChange(e, 'compare')}
          />
        </div>
        <div className="value-wrap">
          <Input
            placeholder="No. of studies"
            value={getValue('value')}
            onChange={(e) => handleChange(e, 'value')}
          />
        </div>
        <div className="value-wrap">
          <div style={{ display: 'flex' }}>
            <Button className="plus-btn" onClick={() => handleAdd(idx, 'studies')}>
              +
            </Button>
            {data.studies.length > 1 && (
            <Button
              className="plus-btn"
              onClick={() => handleMinus(idx, 'studies')}
            >
              -
            </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
