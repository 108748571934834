import { GENERIC_ACTION } from '../SearchResults/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

const GET_COMPLIANCE_PI_FILTERS = 'GET_COMPLIANCE_PI_FILTERS'
const GET_COMPLIANCE_PI_FILTERS_SUCCESS = 'GET_COMPLIANCE_PI_FILTERS_SUCCESS'
const GET_COMPLIANCE_PI_FILTERS_FAILURE = 'GET_COMPLIANCE_PI_FILTERS_FAILURE'
const GET_COMPLIANCE_PI_FILTERS_RESET = 'GET_COMPLIANCE_PI_FILTERS_RESET '

export const getCompliancePiFilterAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_COMPLIANCE_PI_FILTERS,
      url: `${process.env.apiUrl}v0/search/getPiFilters`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getCompliancePiFilterResetAction = () => ({
  type: GET_COMPLIANCE_PI_FILTERS_RESET,
})

export function getCompliancePiFilterReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_COMPLIANCE_PI_FILTERS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COMPLIANCE_PI_FILTERS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_COMPLIANCE_PI_FILTERS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_COMPLIANCE_PI_FILTERS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
