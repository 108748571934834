import React from 'react';
import { Select } from 'antd';

export default function RenderFilterTier(props) {
  const {
    data, setData,
  } = props;

  const handleChange = (e) => {
    setData((old) => {
      const temp = { ...old };
      temp.tier = e;
      return temp;
    });
  };
  const getValue = () => {
    return data.tier;
  };

  return (
    <div className="race-inner-wrap full-width-input">
      <div className="group-input-fields">
        <div className="value-wrap">
          <Select
            mode="multiple"
            style={{
              width: 120,
            }}
            placeholder="Please select"
            options={[
              {
                value: '0',
                label: '0',
              },
              {
                value: '1',
                label: '1',
              },
              {
                value: '2',
                label: '2',
              },
              {
                value: '3',
                label: '3',
              },
            ]}
            value={getValue()}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
    </div>
  );
}
