import React from 'react';
import { Button, Radio } from 'antd';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import { getCookie } from '../../../utils';

const ResearchInterest = ({ data }) => {
  const piQuesResponse = useSelector((store) => store.piQuesResponse);

  const getValue = (id) => {
    return data.researchInterest[id]
  }
  const onChange1 = ({ target: { value } }, id) => {
    data.setresearchInterest((p) => {
      const obj = { ...p };
      obj[id] = value
      return obj
    })
  };

  const OnResetClick = (idd) => {
    data.setresearchInterest((p) => {
      const obj = { ...p };
      delete obj[idd]
      return obj
    })
  }

  return (
    <div className="investigator-page-wrapper">
      <div className="main-head-wrap">Research Interest</div>
      <div className={getCookie('permissions').includes('super_admin') && (data.flag === 'admin' || data.flag === 'edit-admin') ? 'research-interst-step disable-pi' : 'research-interst-step'}>
        {piQuesResponse.flag && piQuesResponse?.data?.data.filter((itm) => itm.category === 'Research interests' && itm.type !== 'label').map((itm) => {
          return (
            <>
              <div className={getCookie('permissions').includes('super_admin') && (data.flag === 'admin' || data.flag === 'edit-admin') ? 'research-interst-card disable-pi-form' : 'research-interst-card'}>
                <div className="header-name" title={itm.question_text}>{itm.question_text}</div>
                <Radio.Group
                  options={itm.values}
                  onChange={(e) => onChange1(e, itm.id)}
                  value={getValue(itm.id)}
                >
                  {itm.options.map((d) => {
                    return <Radio value={d.option_text}>{d.option_text}</Radio>;
                  })}
                </Radio.Group>
                <Button className="reset-btn" onClick={() => OnResetClick(itm.id)}>Reset</Button>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ResearchInterest;
