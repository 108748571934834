import React from 'react'
import {
  Form, Radio, Space, Input, Button, DatePicker,
} from 'antd'
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getCookie } from '../../../utils';

export default function Quality({ data }) {
  const siteQuesResponse = useSelector((store) => store.siteQuesResponse);

  const getValue = (keyName) => {
    return data.quality[keyName];
  };

  const onCenterStateChange = (e, keyName) => {
    const { value } = e.target;
    data.setquality((oldState) => {
      const temp = {};
      temp[keyName] = value;
      return { ...oldState, ...temp };
    });
  };

  const onDateChange = (date, dateString, keyName) => {
    data.setquality((oldState) => {
      const temp = {};
      temp[keyName] = dateString;
      return { ...oldState, ...temp };
    });
    return date
  }

  const showLabelOptions = (itm) => {
    const { child_queston } = itm
    if (child_queston.length) {
      return child_queston?.map((child, idx) => {
        const itemChild = Object.keys(siteQuesResponse?.data?.data)
          .map((res) => siteQuesResponse.data.data[res]).find((o) => o.id === child)
        return renderQuesAns(itemChild, idx)
      })
    }
    return ''
  }

  const showOptions = (itm) => {
    const { options } = itm
    if (options.length) {
      return options.map((opt) => {
        if (getValue(itm.id) === opt.option_text) {
          const childQue = opt.child_questions
          return childQue?.map((child, idx) => {
            const itemChild = Object.keys(siteQuesResponse?.data?.data)
              .map((res) => siteQuesResponse.data.data[res]).find((o) => o.id === child)
            return renderQuesAns(itemChild, idx)
          })
        }
        return ''
      })
    }
    return ''
  }

  const convertDate = () => {
    if (getValue('last_onsite_visit_date')) {
      const visitDate = moment(getValue('last_onsite_visit_date')).format('YYYY-MM-DD')
      return moment(visitDate, 'YYYY-MM-DD')
    }
    return undefined
  }

  const dateFormat = 'YYYY-MM-DD'

  const renderQuesAns = (itm, idx) => {
    return (
      <Form layout="vertical" className={getCookie('permissions').includes('super_admin') && data.flag === 'admin' ? 'disable-pi-form' : null}>
        <div className="head-wrapper">
          <div className="inner-card">

            <div className="question-card">
              <div>{idx + 1 }.</div>
              <Form.Item
                label={itm.category === 'Quality' ? itm.question_text : ''}
                className="question-wrapper"
              >
                {itm.type === 'single_select' ? (
                  <>
                    <Radio.Group onChange={(e) => onCenterStateChange(e, itm.id)} value={getValue(itm.id)}>
                      <Space direction="vertical">
                        {itm.options.map((j) => {
                          return <Radio value={j.option_text}>{j.option_text}</Radio>
                        })}
                      </Space>
                    </Radio.Group>
                    {showOptions(itm)}
                  </>

                ) : itm.type === 'text' ? (
                  <Input
                    className="input-wrapper"
                    placeholder="Please enter"
                    value={getValue(itm.id)}
                    // defaultValue={getValue(itm.id)}
                    onChange={(e) => onCenterStateChange(e, itm.id)}
                  />
                ) : itm.type === 'file_upload' ? (
                  <Button type="primary" disabled>Upload here</Button>
                ) : itm.type === 'date' ? (
                  <Space direction="vertical">
                    <DatePicker
                      onChange={(date, dateString) => onDateChange(date, dateString, itm.id)}
                      value={convertDate()}
                      allowClear={false}
                      format={dateFormat}
                      placeholder={dateFormat}
                    />
                  </Space>
                ) : itm.type === 'label' ? showLabelOptions(itm) : ''}
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    )
  }

  return (
    <div>
      <div className="title-input-sec">Quality</div>
      <div className={getCookie('permissions').includes('super_admin') && data.flag === 'admin' ? 'disable-pi' : null}>
        {siteQuesResponse && siteQuesResponse?.data?.data?.filter((itm) => (itm.category
        === 'Quality' && itm.parent_id === 0)).map((itm, idx) => {
          const childQues = itm.child_queston
          if (childQues.length) {
            childQues.map((child) => {
              return renderQuesAns(siteQuesResponse?.data?.data?.find((o) => o.id === child), idx)
            })
          }
          return renderQuesAns(itm, idx)
        })}
      </div>
    </div>
  )
}
