import React from 'react'
import {
  Button,
  Form, Input, Radio, Space,
} from 'antd'
import { useSelector } from 'react-redux';
import { getCookie } from '../../../utils';

export default function QualityParent({ data }) {
  const siteQuesResponse = useSelector((store) => store.siteQuesResponse);

  const getValue = (keyName) => {
    return data.qualityParent[keyName];
  };

  const onCenterStateChange = (e, keyName) => {
    const { value } = e.target;
    data.setqualityParent((oldState) => {
      const temp = {};
      temp[keyName] = value;
      return { ...oldState, ...temp };
    });
  };

  const showLabelOptions = (itm) => {
    const { child_queston } = itm
    if (child_queston.length) {
      return child_queston?.map((child, idx) => {
        const itemChild = Object.keys(siteQuesResponse?.data?.data)
          .map((res) => siteQuesResponse.data.data[res]).find((o) => o.id === child)
        return renderQuesAns(itemChild, idx)
      })
    }
    return ''
  }

  const renderQuesAns = (itm, idx) => {
    return (
      <Form layout="vertical" className={getCookie('permissions').includes('super_admin') && data.flag === 'admin' ? 'disable-pi-form' : null}>
        <div className="head-wrapper">
          <div className="inner-card">

            <div className="question-card">
              <div>{idx + 1 }.</div>
              <Form.Item
                label={itm.question_text}
                className="question-wrapper"
              >
                {itm.type === 'single_select' ? (
                  <>
                    <Radio.Group onChange={(e) => onCenterStateChange(e, itm.id)} value={getValue(itm.id)}>
                      <Space direction="vertical">
                        {itm.options.map((j) => {
                          return <Radio value={j.option_text}>{j.option_text}</Radio>
                        })}
                      </Space>
                    </Radio.Group>
                    {showOptions(itm)}
                  </>
                ) : itm.type === 'text' ? (
                  <>
                    <Input
                      className="input-wrapper"
                      placeholder="Please enter"
                      value={getValue(itm.id)}
                      // defaultValue={getValue(itm.id)}
                      onChange={(e) => onCenterStateChange(e, itm.id)}
                    />
                    {showOptions(itm)}
                  </>
                ) : itm.type === 'file_upload' ? (
                  <Button type="primary" disabled>Upload here</Button>
                ) : itm.type === 'label' ? showLabelOptions(itm) : ''}
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    )
  }

  const showOptions = (itm) => {
    const { options } = itm
    if (options.length) {
      return options.map((opt) => {
        if (getValue(itm.id) === opt.option_text) {
          const childQue = opt.child_questions
          return childQue?.map((child, idx) => {
            const itemChild = Object.keys(siteQuesResponse?.data?.data)
              .map((res) => siteQuesResponse.data.data[res]).find((o) => o.id === child)
            return renderQuesAns(itemChild, idx)
          })
        }
        return ''
      })
    }
    return ''
  }

  return (
    <div>
      <div className="title-input-sec">Quality - Parent</div>
      <div className={getCookie('permissions').includes('super_admin') && data.flag === 'admin' ? 'disable-pi' : null}>
        {siteQuesResponse && siteQuesResponse?.data?.data?.filter((itm) => (itm.category
        === 'Quality - Parent' && itm.parent_id === 0)).map((itm, idx) => {
          const childQues = itm.child_queston
          if (childQues.length) {
            childQues.map((child) => {
              return renderQuesAns(siteQuesResponse?.data?.data?.find((o) => o.id === child), idx)
            })
          }
          return renderQuesAns(itm, idx)
        })}
      </div>
    </div>
  )
}
