import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchResultFilterAction } from '../SearchResults/logic';

export default function RenderFilterAllianceType(props) {
  const {
    data, setData, fieldToSearch,
  } = props;

  const { Option } = Select;
  const dispatch = useDispatch();

  const searchResultFilterData = useSelector((state) => state.searchResultFilterData);

  useEffect(() => {
    dispatch(getSearchResultFilterAction({
      dataset: fieldToSearch === 'parent_centers' ? 'centers' : fieldToSearch,
      type: 'alliance_type',
    }, {
      filter_type: 'alliance_type',
      key: 'alliance_type',
    }))
  }, []);

  const handleChange = (e) => {
    setData((old) => {
      const temp = { ...old };
      let value = '';
      value = e;
      temp.alliance_type = value;
      return temp;
    });
  };

  const getValue = () => {
    return data.alliance_type;
  };

  return (
    <div className="race-inner-wrap full-width-input">
      <div className="advanced-search-content-input-sec alliance_type-inner-wrap">
        <div className="searchresults-filter-section-filter">
          <div className="searchresults-filter-section-filter-select">
            <div className="group-input-fields">
              <div className="value-wrap">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Search Alliance Type"
                  onChange={(e) => handleChange(e)}
                  // maxTagCount={1}
                  value={getValue()}
                >
                  {searchResultFilterData && searchResultFilterData?.data?.alliance_type?.map((filterItm, index) => (
                    <Option key={index} value={filterItm.key}>
                      {filterItm.category}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
