import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_PERFORMANCE_DATA = 'GET_PERFORMANCE_DATA'
const GET_PERFORMANCE_DATA_SUCCESS = 'GET_PERFORMANCE_DATA_SUCCESS'
const GET_PERFORMANCE_DATA_FAILURE = 'GET_PERFORMANCE_DATA_FAILURE'
const GET_PERFORMANCE_DATA_RESET = 'GET_PERFORMANCE_DATA_RESET'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getPerformanceResetAction = () => (
  {
    type: GET_PERFORMANCE_DATA_RESET,
    payload: {},
  })

export const getPerformanceAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PERFORMANCE_DATA,
    url: `${process.env.apiUrl}/v0/search/recruitmentPerformance`,
    method: 'GET',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getPerformanceReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PERFORMANCE_DATA: {
      return {
        data: {},
        loading: true,
        flag: true,
        error: false,
      }
    }
    case GET_PERFORMANCE_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PERFORMANCE_DATA_RESET: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PERFORMANCE_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_KMR_DATA = 'GET_KMR_DATA'
const GET_KMR_DATA_SUCCESS = 'GET_KMR_DATA_SUCCESS'
const GET_KMR_DATA_FAILURE = 'GET_KMR_DATA_FAILURE'
const GET_KMR_DATA_RESET = 'GET_KMR_DATA_RESET'

export const getKmrAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_KMR_DATA,
    url: `${process.env.apiUrl}/v0/search/kmrBenchmarks`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getKmrResetAction = () => (
  {
    type: GET_KMR_DATA_RESET,
    payload: {},
  })

export function getKmrReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_KMR_DATA: {
      return {
        data: {},
        loading: true,
        flag: true,
        error: false,
      }
    }
    case GET_KMR_DATA_SUCCESS: {
      if (action.meta.countryName) {
        return {
          data: {
            ...state.data, [action.meta.countryName]: action.payload,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_KMR_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_KMR_DATA_RESET: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

const GET_PERFORMANCE_STUDIES_DATA = 'GET_PERFORMANCE_STUDIES_DATA'
const GET_PERFORMANCE_STUDIES_DATA_MORE = 'GET_PERFORMANCE_STUDIES_DATA_MORE'
const GET_PERFORMANCE_STUDIES_DATA_MORE_SUCCESS = 'GET_PERFORMANCE_STUDIES_DATA_MORE_SUCCESS'
const GET_PERFORMANCE_STUDIES_DATA_SUCCESS = 'GET_PERFORMANCE_STUDIES_DATA_SUCCESS'
const GET_PERFORMANCE_STUDIES_DATA_RESET = 'GET_PERFORMANCE_STUDIES_DATA_RESET'
const GET_PERFORMANCE_STUDIES_DATA_FAILURE = 'GET_PERFORMANCE_STUDIES_DATA_FAILURE'

export const getPerformanceStudiesReset = () => ({
  type: GET_PERFORMANCE_STUDIES_DATA_RESET,
})

export const getPerformanceStudies = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PERFORMANCE_STUDIES_DATA,
    url: `${process.env.apiUrl}v0/search/deepDiveRecrtPerformance`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getPerformanceStudiesMore = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PERFORMANCE_STUDIES_DATA_MORE,
    url: `${process.env.apiUrl}v0/search/deepDiveRecrtPerformance`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getPerformanceStudiesReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PERFORMANCE_STUDIES_DATA: {
      return {
        data: {},
        loading: true,
        flag: true,
        error: false,
      }
    }
    case GET_PERFORMANCE_STUDIES_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_PERFORMANCE_STUDIES_DATA_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_PERFORMANCE_STUDIES_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_PERFORMANCE_STUDIES_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_RP_FILTERS = 'GET_RP_FILTERS'
const GET_RP_FILTERS_SUCCESS = 'GET_RP_FILTERS_SUCCESS'
const GET_RP_FILTERS_RESET = 'GET_RP_FILTERS_RESET'
const GET_RP_FILTERS_FAILURE = 'GET_RP_FILTERS_FAILURE'

export const getRpFiltersAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_RP_FILTERS,
      url: `${process.env.apiUrl}v0/search/countryProfileFilters`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const fetchCountryResetAction = () => ({
  type: GET_RP_FILTERS_RESET,
})

export function getRpFiltersReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_RP_FILTERS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_RP_FILTERS_SUCCESS: {
      if (action.meta.filter_type) {
        return {
          data: {
            ...state.data, [action.meta.filter_type]: action.payload,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_RP_FILTERS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_RP_FILTERS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
