import React, { useEffect, useState } from 'react'
import {
  Button,
  Form, Input, Radio, Space, Collapse, Select,
} from 'antd'
import './other.scss'
import { getCookie } from '../../../utils';

const { Panel } = Collapse;
export const RenderEditForm = ({ editForm, setEditform }) => {
  const { Option } = Select;
  const [showInput, setInput] = useState(null);
  const getEditValue = (keyName) => {
    return editForm[keyName].value;
  };

  const onEditDataStateChange = (e, keyName) => {
    let value
    if (keyName === 'role') {
      setInput(e)
      value = e;
    } else {
      value = e.target.value;
    }
    setEditform((oldState) => {
      const newState = { ...oldState }
      newState[keyName] = value
      return newState
    });
  };

  const onEditstateChange = (e, keyName) => {
    const { value } = e.target;
    setEditform((oldState) => {
      const newState = { ...oldState }
      newState[keyName] = [value]
      return newState
    });
  };

  return (
    <div className="head-wrapper">
      <div className="inner-card">
        <div className="role-card">
          <Form.Item
            label="1.Role"
            className="role-wrapper"
          >
            <Select
              className="input-wrapper"
              placeholder="Select role"
              value={getEditValue('role')}
              onChange={(e) => onEditDataStateChange(e, 'role')}
            >
              <Option value="Admin">Admin</Option>
              <Option value="Alliance Contact">Alliance Contact</Option>
              <Option value="Blinded Rater">Blinded Rater</Option>
              <Option value="Blinded Reader">Blinded Reader</Option>
              <Option value="Contract Contact">Contract Contact</Option>
              <Option value="Contract and Grant Contact">Contract and Grant Contact</Option>
              <Option value="Data Entry">Data Entry</Option>
              <Option value="Dermatologist">Dermatologist</Option>
              <Option value="Dietician">Dietician</Option>
              <Option value="Drug Recipient">Drug Recipient</Option>
              <Option value="EC Chairperson">EC Chairperson</Option>
              <Option value="Grant Contact">Grant Contact</Option>
              <Option value="Head Of Department">Head Of Department</Option>
              <Option value="IRB Contact">IRB Contact</Option>
              <Option value="Lab Report Receiver">Lab Report Receiver</Option>
              <Option value="Lab Sample Pickup">Lab Sample Pickup</Option>
              <Option value="Laboratory Technician">Laboratory Technician</Option>
              <Option value="Material Recipient">Material Recipient</Option>
              <Option value="Nurse">Nurse</Option>
              <Option value="Ophthalmologist">Ophthalmologist</Option>
              <Option value="Pharmacist">Pharmacist</Option>
              <Option value="Radiologist">Radiologist</Option>
              <Option value="Regulatory Contact">Regulatory Contact</Option>
              <Option value="Site Safety Coordinator">Site Safety Coordinator</Option>
              <Option value="Sponsor Project Manager">Sponsor Project Manager</Option>
              <Option value="Study Coordinator">Study Coordinator</Option>
              <Option value="Sub Investigator">Sub Investigator</Option>
              <Option value="Unblinded Investigator">Unblinded Investigator</Option>
              <Option value="Unblinded Study Coordinator">Unblinded Study Coordinator</Option>
            </Select>
          </Form.Item>
        </div>
        {showInput === 'Head Of Department' && (
        <div className="card">
          <Form.Item
            label="Enter department name"
            className="wrapper"
            rules={[{ type: 'string', min: 3, message: 'Minimum 3 characters' }]}
          >
            <Input
              className="input-wrapper"
              placeholder="Enter department name"
              value={getEditValue('name_of_department')}
              onChange={(e) => onEditDataStateChange(e, 'name_of_department')}
            />
          </Form.Item>
        </div>
        )}

        <div className="fname-card">
          <Form.Item
            label="2.First Name"
            className="fname-wrapper"
          >
            <Input
              className="input-wrapper"
              placeholder="Enter first name"
              value={getEditValue('first_name')}
              onChange={(e) => onEditDataStateChange(e, 'first_name')}
            />
          </Form.Item>
        </div>

        <div className="lname-card">
          <Form.Item
            label="3.Last Name"
            className="lname-wrapper "
          >
            <Input
              className="input-wrapper"
              placeholder="Enter last name"
              value={getEditValue('last_name')}
              onChange={(e) => onEditDataStateChange(e, 'last_name')}
            />
          </Form.Item>
        </div>

        <div className="phone-card">
          <Form.Item
            label="4.Phone Number"
            className="phone-wrapper "
          >
            <Input
              className="input-wrapper"
              placeholder="Enter phone number"
              value={getEditValue('mobile_number')}
              onChange={(e) => onEditstateChange(e, 'mobile_number')}
            />
          </Form.Item>
        </div>
        <div className="fix-card">
          <Form.Item
            label="5.Fax Number"
            className="fax-wrapper "
          >
            <Input
              className="input-wrapper"
              placeholder="Enter fax number"
              value={getEditValue('fax')}
              onChange={(e) => onEditstateChange(e, 'fax')}
            />
          </Form.Item>
        </div>
        <div className="email-card">
          <Form.Item
            label="6.Email"
            className="email-wrapper "
          >
            <Input
              className="input-wrapper"
              placeholder="Enter email"
              value={getEditValue('email')}
              onChange={(e) => onEditstateChange(e, 'email')}
            />
          </Form.Item>
        </div>
        <div className="location-card">
          <Form.Item
            label="7. Same Location?"
            className="location-wrapper "
          >
            <Radio.Group
              value={getEditValue('same_location')}
              onChange={(e) => onEditDataStateChange(e, 'same_location')}
            >
              <Space direction="vertical">
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
    </div>
  )
}

export default function OtherKeyPersonnel({ data }) {
  let defaultOther
  if (data.flag === 'fromMainCenter') {
    defaultOther = {
      role: '', name_of_department: '', first_name: '', last_name: '', mobile_number: [], fax: [], email: [], same_location: '',
    }
  } else {
    defaultOther = {
      role: { value: '', flag: false },
      name_of_department: { value: '', flag: false },
      first_name: { value: '', flag: false },
      last_name: { value: '', flag: false },
      mobile_number: { value: [], flag: false },
      fax: { value: [], flag: false },
      email: { value: [], flag: false },
      same_location: { value: '', flag: false },
    }
  }

  const [otherData, setOtherData] = useState(defaultOther)
  const [editForm, setEditform] = useState(defaultOther);
  const [showInput, setInput] = useState(null);
  const [showSave, setSave] = useState({ state: false, index: 0 })
  const { Option } = Select;

  useEffect(() => {
    setOtherData(defaultOther)
  }, [data.otherData])
  const [form] = Form.useForm();

  const getValue = (keyName) => {
    return otherData[keyName].value;
  };

  const onOtherDataStateChange = (e, keyName) => {
    let value;
    if (keyName === 'role') {
      setInput(e)
      value = e;
    } else {
      value = e.target.value
    }
    if (data.flag === 'fromMainCenter') {
      setOtherData((oldState) => {
        const temp = {};
        temp[keyName] = value;
        return { ...oldState, ...temp };
      });
    } else {
      setOtherData((oldState) => {
        const temp = {};
        temp[keyName] = { value, flag: true };
        return { ...oldState, ...temp };
      });
    }
  }

  const onstateChange = (e, keyName) => {
    const { value } = e.target;
    if (data.flag === 'fromMainCenter') {
      setOtherData((oldState) => {
        const temp = {};
        temp[keyName] = [value];
        return { ...oldState, ...temp };
      });
    } else {
      setOtherData((oldState) => {
        const temp = {};
        temp[keyName] = [{ value, flag: true }];
        return { ...oldState, ...temp };
      });
    }
  };

  const renderForm = () => {
    return (
      <Form layout="vertical" form={data.otherDataForm} name="otherform1" className={data.flag === 'admin' ? 'disable-pi-form' : null}>
        <div className="head-wrapper">
          <div className="inner-card">
            <div className="role-card">
              <Form.Item
                label="1.Role"
                className="wrapper-card"
                name="role"
              >
                <Select
                  className="input-wrapper"
                  placeholder="Select role"
                  value={getValue('role')}
                  // defaultValue={getValue('role')}
                  onChange={(e) => onOtherDataStateChange(e, 'role')}
                >
                  <Option value="Admin">Admin</Option>
                  <Option value="Alliance Contact">Alliance Contact</Option>
                  <Option value="Blinded Rater">Blinded Rater</Option>
                  <Option value="Blinded Reader">Blinded Reader</Option>
                  <Option value="Contract Contact">Contract Contact</Option>
                  <Option value="Contract and Grant Contact">Contract and Grant Contact</Option>
                  <Option value="Data Entry">Data Entry</Option>
                  <Option value="Dermatologist">Dermatologist</Option>
                  <Option value="Dietician">Dietician</Option>
                  <Option value="Drug Recipient">Drug Recipient</Option>
                  <Option value="EC Chairperson">EC Chairperson</Option>
                  <Option value="Grant Contact">Grant Contact</Option>
                  <Option value="Head Of Department">Head Of Department</Option>
                  <Option value="IRB Contact">IRB Contact</Option>
                  <Option value="Lab Report Receiver">Lab Report Receiver</Option>
                  <Option value="Lab Sample Pickup">Lab Sample Pickup</Option>
                  <Option value="Laboratory Technician">Laboratory Technician</Option>
                  <Option value="Material Recipient">Material Recipient</Option>
                  <Option value="Nurse">Nurse</Option>
                  <Option value="Ophthalmologist">Ophthalmologist</Option>
                  <Option value="Pharmacist">Pharmacist</Option>
                  <Option value="Radiologist">Radiologist</Option>
                  <Option value="Regulatory Contact">Regulatory Contact</Option>
                  <Option value="Site Safety Coordinator">Site Safety Coordinator</Option>
                  <Option value="Sponsor Project Manager">Sponsor Project Manager</Option>
                  <Option value="Study Coordinator">Study Coordinator</Option>
                  <Option value="Sub Investigator">Sub Investigator</Option>
                  <Option value="Unblinded Investigator">Unblinded Investigator</Option>
                  <Option value="Unblinded Study Coordinator">Unblinded Study Coordinator</Option>
                </Select>
              </Form.Item>
            </div>
            {showInput === 'Head Of Department' && (
            <div className="card">
              <Form.Item
                label="Enter department name"
                className="wrapper"
                rules={[{ type: 'string', min: 3, message: 'Minimum 3 characters' }]}
              >
                <Input
                  className="input-wrapper"
                  placeholder="Enter department name"
                  value={getValue('name_of_department')}
                  onChange={(e) => onOtherDataStateChange(e, 'name_of_department')}
                />
              </Form.Item>
            </div>
            )}

            <div className="fname-card">
              <Form.Item
                label="2.First Name"
                className="fname-wrapper"
                rules={[{ type: 'string', min: 3, message: 'Minimum 3 characters' }]}
              >
                <Input
                  className="input-wrapper"
                  placeholder="Enter first name"
                  value={getValue('first_name')}
                  onChange={(e) => onOtherDataStateChange(e, 'first_name')}
                />
              </Form.Item>
            </div>

            <div className="lname-card">
              <Form.Item
                label="3.Last Name"
                rules={[{ type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                className="lname-wrapper "
              >
                <Input
                  className="input-wrapper"
                  placeholder="Enter last name"
                  value={getValue('last_name')}
                  onChange={(e) => onOtherDataStateChange(e, 'last_name')}
                />
              </Form.Item>
            </div>

            <div className="phone-card">
              <Form.Item
                label="4.Phone Number"
                className="phone-wrapper "
              >
                <Input
                  className="input-wrapper"
                  placeholder="Enter phone number"
                  value={getValue('mobile_number')}
                  onChange={(e) => onstateChange(e, 'mobile_number')}
                />
              </Form.Item>
            </div>
            <div className="fix-card">
              <Form.Item
                label="5.Fax Number"
                className="fax-wrapper "
              >
                <Input
                  className="input-wrapper"
                  placeholder="Enter fax number"
                  value={getValue('fax')}
                  onChange={(e) => onstateChange(e, 'fax')}
                />
              </Form.Item>
            </div>
            <div className="email-card">
              <Form.Item
                label="6.Email"
                className="email-wrapper "
                name="email"
                rules={[{ type: 'email', message: 'Enter a valid email' }]}
              >
                <Input
                  className="input-wrapper"
                  placeholder="Enter email"
                  value={getValue('email')}
                  onChange={(e) => onstateChange(e, 'email')}
                />
              </Form.Item>
            </div>
            <div className="location-card">
              <Form.Item
                label="7. Same Location?"
                className="location-wrapper "
              >
                <Radio.Group
                  value={getValue('same_location')}
                  onChange={(e) => onOtherDataStateChange(e, 'same_location')}
                >
                  <Space direction="vertical">
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    )
  }

  const onChange = () => {
    return null
  };

  const buttonClick = (event, idx) => {
    setSave({ state: false, index: idx })
    setEditform({ ...defaultOther, ...data.otherData[idx] })
    data.onSaveAndAddMoreClick(setOtherData, editForm, form, 'saveEdit', idx)
  }

  const buttonEditClick = (event, idx) => {
    setEditform({ ...defaultOther, ...data.otherData[idx] })
    setSave({ state: true, index: idx })
    form.resetFields()
  }

  const configRequired = {
    0: ['role', 'first_name', 'last_name'],
  }

  const isDisabled = () => {
    const firstStep = !configRequired[0]?.every((itm) => {
      return !!otherData[itm].value
    })
    return firstStep
  }

  return (
    <div className="header-wrap">
      <div className={getCookie('permissions').includes('super_admin') && (data.flag === 'admin' || data.flag === 'edit-admin') ? 'input-row-section-step disable-pi' : 'input-row-section-step'}>
        <div className="input-row-section-step-title">Other Key Personnel</div>
        <div className="input-row-section-step-row">
          <Form form={form} layout="vertical" className={getCookie('permissions').includes('super_admin') && (data.flag === 'admin' || data.flag === 'edit-admin') ? 'disable-pi-form' : null}>
            {renderForm()}
            <div className="save-and-add-btn">
              <Button
                className="add-btn"
                type="primary"
                onClick={() => {
                  data.onSaveAndAddMoreClick(setOtherData, otherData, form);
                  setEditform(otherData)
                }}
                disabled={isDisabled()}
              >Save and Add
              </Button>
            </div>
            <div className="input-row-section-step-row-collapse">
              <Collapse onChange={onChange} accordion>
                {data.otherData.map((d, idx) => {
                  return (
                    <Panel
                      header={(data.flag !== 'fromMainCenter'
                        ? (
                          <div className="header-save-add-title">
                            <span className="role">{d.role.value}</span>
                            <span className="fname">{d.first_name.value}</span>
                            <span className="lname">{d.last_name.value}</span>
                          </div>
                        ) : (
                          <div className="header-save-add-title">
                            <span className="role">{d.role}</span>
                            <span className="fname">{d.first_name}</span>
                            <span className="lname">{d.last_name}</span>
                          </div>
                        )
)}
                      key={idx}
                      extra={showSave.state && showSave.index === idx ? (
                        <Button size="small" onClick={(e) => buttonClick(e, idx)}>
                          Save
                        </Button>
                      ) : (
                        <Button size="small" onClick={(e) => buttonEditClick(e, idx)}>
                          Edit
                        </Button>
                      )}
                    >
                      <>
                        <RenderEditForm editForm={editForm} setEditform={setEditform} idx={idx} />
                      </>
                    </Panel>
                  )
                })}
              </Collapse>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
