import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import Loader from '../../../common/components/Loader';
import { getPurpleLabDataForSiteEffectivenessAction, getPurpleLabDataForSiteEffectivenessMoreAction } from './logic';

const columns = [
  {
    title: 'Mortality (in %)',
    dataIndex: 'Mortality',
    key: 'Mortality',
  },
  {
    title: 'Complications (in %)',
    dataIndex: 'Complications',
    key: 'Complications',
  },
  {
    title: 'Days in Acute Care (DACS) (in %)',
    dataIndex: 'Days in Acute Care (DACS)',
    key: 'Days in Acute Care (DACS)',
  },
];

const PurpleLabDataForCenter = () => {
  const [from, setFrom] = useState(1);
  const [data, setData] = useState([])

  const dispatch = useDispatch();
  const getPurpleLabDataForSiteEffectivenessResponse = useSelector(
    (reduxState) => reduxState.getPurpleLabDataForSiteEffectivenessResponse || {},
  );

  useEffect(() => {
    dispatch(
      getPurpleLabDataForSiteEffectivenessAction({
        hcotype: 'Effectiveness',
        id: parse(location.search).id,
        page: 1,
        size: 10,
      }),
    );
  }, []);

  useEffect(() => {
    if (getPurpleLabDataForSiteEffectivenessResponse.flag) {
      if (getPurpleLabDataForSiteEffectivenessResponse?.data?.data?.length) {
        const fields = ['hco_mortality_rate', 'national_mortality_rate', 'hco_complications_rate', 'national_complications_rate', 'hco_dacs_rate', 'national_dacs_rate']
        const data1 = getPurpleLabDataForSiteEffectivenessResponse?.data?.data?.map((i) => Object.fromEntries(fields.map((f) => [f, i[f]])))
        setData(data1)
      }
    }
  }, [JSON.stringify(getPurpleLabDataForSiteEffectivenessResponse)]);

  const loadMoreRows = () => {
    setFrom(from + 1);
    dispatch(
      getPurpleLabDataForSiteEffectivenessMoreAction({
        hcotype: 'Effectiveness',
        id: parse(location.search).id,
        page: from + 1,
        size: 10,
      }),
    );
  };

  const renderValues = (value) => {
    const data1 = (value / 2) * 100
    if (data1 > 80) {
      return '(Significantly Better than Average)'
    }
    if (data1 > 60 && data1 < 80) {
      return '(Better than Average)'
    }
    if (data1 > 40 && data1 < 60) {
      return '(Average)'
    }
    if (data1 > 20 && data1 < 40) {
      return '(Worse than Average)'
    }
    return '(Significantly Worse than Average)'
  }

  const renderTable = () => {
    return data.map((itm) => {
      return (
        <div style={{ display: 'flex' }} className="table-content">
          <div className="table-content-data">
            <span className="table-content-data-value">
              {(((itm.hco_mortality_rate + itm.national_mortality_rate) / 2) * 100).toFixed(2)} &nbsp;
            </span>
            <span className="table-content-data-desc">
              <Tooltip title={renderValues((itm.hco_mortality_rate + itm.national_mortality_rate))}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          </div>
          <div className="table-content-data">
            <span className="table-content-data-value">{(((itm.hco_complications_rate + itm.national_complications_rate) / 2) * 100).toFixed(2)} &nbsp;</span>
            <span className="table-content-data-desc">
              <Tooltip title={renderValues(itm.hco_complications_rate + itm.national_complications_rate)}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          </div>
          <div className="table-content-data">
            <span className="table-content-data-value">{(((itm.hco_dacs_rate + itm.national_dacs_rate) / 2) * 100).toFixed(2)} &nbsp;</span>
            <span className="table-content-data-desc">
              <Tooltip title={renderValues(itm.hco_dacs_rate + itm.national_dacs_rate)}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          </div>
        </div>

      )
    })
  };

  const renderHeader = () => {
    return (
      <div className="table-row table-header-for-effectiveness">
        {columns.map((colItm, index) => (
          <div
            className="table-row-item"
            key={index}
          >
            {colItm.title}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="puplelab-data-for-center">
      <div className="header-content">
        <div className="table-title">Effectiveness : </div>
      </div>
      <Loader loading={getPurpleLabDataForSiteEffectivenessResponse.loading}>
        <div className="render-table-header-for-effectiveness-PI">{renderHeader()}</div>
        <div className="render-table-for-effectiveness-PI">
          <LazyLoadDiv
            className="card-list scrollbar"
            id="pi-list-in-search"
            total={getPurpleLabDataForSiteEffectivenessResponse.data.total}
            currentTotal={(getPurpleLabDataForSiteEffectivenessResponse.data.data || []).length}
            loadMoreRows={() => loadMoreRows()}
            height="calc(100vh - 528px)"
          >
            <div className="render-table-content-for-effectiveness-PI">
              {data?.length ? renderTable() : <div className="no-data-found-msg-for-effectiveness">No relevent data found</div>}
            </div>
          </LazyLoadDiv>
        </div>
      </Loader>
    </div>
  );
};

export default PurpleLabDataForCenter;
