import { GENERIC_ACTION } from '../SearchResults/logic'

const LIST_OF_REQUESTS_NEW = 'LIST_OF_REQUESTS_NEW'
const LIST_OF_MORE_REQUESTS_NEW = 'LIST_OF_MORE_REQUESTS_NEW'
const LIST_OF_REQUESTS_NEW_SUCCESS = 'LIST_OF_REQUESTS_NEW_SUCCESS'
const LIST_OF_MORE_REQUESTS_NEW_SUCCESS = 'LIST_OF_MORE_REQUESTS_NEW_SUCCESS'
const LIST_OF_REQUESTS_NEW_RESET = 'LIST_OF_REQUESTS_NEW_RESET'
const LIST_OF_REQUESTS_NEW_FAILURE = 'LIST_OF_REQUESTS_NEW_FAILURE'

const LIST_OF_REQUESTS_EDITED = 'LIST_OF_REQUESTS_EDITED'
const LIST_OF_REQUESTS_EDITED_SUCCESS = 'LIST_OF_REQUESTS_EDITED_SUCCESS'
const LIST_OF_REQUESTS_MORE_EDITED = 'LIST_OF_REQUESTS_MORE_EDITED'
const LIST_OF_REQUESTS_MORE_EDITED_SUCCESS = 'LIST_OF_REQUESTS_MORE_EDITED_SUCCESS'
const LIST_OF_REQUESTS_EDITED_RESET = 'LIST_OF_REQUESTS_EDITED_RESET'
const LIST_OF_REQUESTS_EDITED_FAILURE = 'LIST_OF_REQUESTS_EDITED_FAILURE'

const LIST_OF_REQUESTS_DELETED = 'LIST_OF_REQUESTS_DELETED'
const LIST_OF_REQUESTS_DELETED_SUCCESS = 'LIST_OF_REQUESTS_DELETED_SUCCESS'
const LIST_OF_REQUESTS_MORE_DELETED = 'LIST_OF_REQUESTS_MORE_DELETED'
const LIST_OF_REQUESTS_MORE_DELETED_SUCCESS = 'LIST_OF_REQUESTS_MORE_DELETED_SUCCESS'
const LIST_OF_REQUESTS_DELETED_RESET = 'LIST_OF_REQUESTS_DELETED_RESET'
const LIST_OF_REQUESTS_DELETED_FAILURE = 'LIST_OF_REQUESTS_DELETED_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getNewListOfRequestsDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: LIST_OF_REQUESTS_NEW,
      url: `${process.env.apiUrl}v0/search/listOfRequestsNewSitePi`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getNewListOfMoreRequestsDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: LIST_OF_MORE_REQUESTS_NEW,
      url: `${process.env.apiUrl}v0/search/listOfRequestsNewSitePi`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getNewListOfRequestsDataResetAction = () => ({
  type: LIST_OF_REQUESTS_NEW_RESET,
})

export function getNewListOfRequestsDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case LIST_OF_REQUESTS_NEW: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case LIST_OF_REQUESTS_NEW_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case LIST_OF_REQUESTS_NEW_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case LIST_OF_REQUESTS_NEW_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case LIST_OF_MORE_REQUESTS_NEW_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    default:
      return state
  }
}

export const getEditedListOfRequestsDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: LIST_OF_REQUESTS_EDITED,
      url: `${process.env.apiUrl}v0/search/listOfRequestsEditedSitePi`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getEditedListOfMoreRequestsDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: LIST_OF_REQUESTS_MORE_EDITED,
      url: `${process.env.apiUrl}v0/search/listOfRequestsEditedSitePi`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getEditedListOfRequestsDataResetAction = () => ({
  type: LIST_OF_REQUESTS_EDITED_RESET,
})

export function getEditedListOfRequestsDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case LIST_OF_REQUESTS_EDITED: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case LIST_OF_REQUESTS_EDITED_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case LIST_OF_REQUESTS_EDITED_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case LIST_OF_REQUESTS_EDITED_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case LIST_OF_REQUESTS_MORE_EDITED_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    default:
      return state
  }
}

export const getDeletedListOfRequestsDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: LIST_OF_REQUESTS_DELETED,
      url: `${process.env.apiUrl}v0/search/listOfRequestsDeletePi`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getDeletedListOfMoreRequestsDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: LIST_OF_REQUESTS_MORE_DELETED,
      url: `${process.env.apiUrl}v0/search/listOfRequestsDeletePi`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getDeletedListOfRequestsDataResetAction = () => ({
  type: LIST_OF_REQUESTS_DELETED_RESET,
})

export function getDeletedListOfRequestsDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case LIST_OF_REQUESTS_DELETED: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case LIST_OF_REQUESTS_DELETED_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case LIST_OF_REQUESTS_DELETED_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case LIST_OF_REQUESTS_DELETED_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case LIST_OF_REQUESTS_MORE_DELETED_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    default:
      return state
  }
}
