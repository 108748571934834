import { Button } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { parse } from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../common/components/Loader'
import { getPreferredSiteAction, changePreferredSiteAction } from '../SavedProjectResults/logic'

const OrgChangeModal = ({ orgChangeModalData, setOrgChangeModal }) => {
  const dispatch = useDispatch()
  const listOfOrgs = useSelector((store) => store.preferredSiteList)
  const [listOfCenters, setListOfCenters] = useState([])
  const [centerSelected, setCenterSelected] = useState({})
  const [confirmationModal, setConfirmationModal] = useState(false)

  useEffect(() => {
    dispatch(
      getPreferredSiteAction(
        {
          id: orgChangeModalData.piId,
        },
      ),
    )
  }, [JSON.stringify(orgChangeModalData.piId)])

  useEffect(() => {
    if (listOfOrgs.flag) {
      setListOfCenters(listOfOrgs.data)
    }
  }, [JSON.stringify(listOfOrgs)])

  const selectCenterClicked = () => {
    const pi_id = orgChangeModalData.piId
    const { project_id } = parse(location.search)
    const isProject = true
    dispatch(changePreferredSiteAction(
      {
        pi_id,
        project_id,
        isProject,
        body: JSON.stringify({
          'primary_organization.norm_affiliation_v3':
            [
              {
                affiliation_id: {
                  old: orgChangeModalData.siteId,
                  new: centerSelected.id,
                  flag: true,
                },
              },
            ],
        }),
      },
    ))
  }

  return (
    <>
      <Modal
        className="change-preferred-site-modal project-for-site-modal"
        visible={orgChangeModalData.isOpen}
        onCancel={() => setOrgChangeModal({ isOpen: false, piId: '', isAlliance: null })}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        title="Change the preferred site to"
      >
        <Loader loading={listOfOrgs.loading} error={listOfOrgs.error}>
          <div className="orglist-main">
            {
              listOfCenters.length !== 0
              && (
                <div className="preferred-site-alliance sec-bottom-border">
                  <div className="preferred-site-alliance-title">
                    Primary Center
                  </div>
                  {listOfCenters.map((itm) => (itm.type === 'Primary Organization'
                    ? (
                      <div className="orglist-card">
                        <div className="orgname">
                          {itm.name}
                        </div>
                        <div className="org-select-btn">
                          <Button
                            onClick={
                              () => {
                                setConfirmationModal(true);
                                setCenterSelected(itm);
                              }
                            }
                            disabled={orgChangeModalData.siteId === itm.id}
                          >
                            {orgChangeModalData.siteId === itm.id ? 'Selected' : 'Select Center'}
                          </Button>
                        </div>
                      </div>
                    )
                    : null))}
                </div>
              )
            }
            {
              listOfCenters.length !== 0 && orgChangeModalData.isAlliance
              && (
                <div className="preferred-site-alliance sec-bottom-border">
                  <div className="preferred-site-alliance-title">
                    Alliance Center
                  </div>
                  {listOfCenters.map((itm) => (itm.type && itm.type === 'Alliance Member' ? (
                    <div className="orglist-card">
                      <div className="orgname">
                        {itm.name}
                      </div>
                      <div className="org-select-btn">
                        <Button
                          onClick={
                            () => {
                              setConfirmationModal(true);
                              setCenterSelected(itm);
                            }
                          }
                          disabled={orgChangeModalData.siteId === itm.id}
                        >
                          {orgChangeModalData.siteId === itm.id ? 'Selected' : 'Select Center'}
                        </Button>
                      </div>
                    </div>
                  ) : null))}
                </div>
              )
            }
            {
              listOfCenters.length !== 0
              && (
                <div className="preferred-site-alliance sec-bottom-border">
                  <div style={{ display: 'flex' }}>
                    <div className="preferred-site-alliance-title other-center">
                      Other Centers
                    </div>
                    <div className="preferred-site-alliance-title parent-institution">
                      Parent Institution
                    </div>
                    <div className="preferred-site-alliance-title is-alliance">
                      Is Alliance?
                    </div>
                    <div className="preferred-site-alliance-title org-select-btn" />
                  </div>
                  {listOfCenters.map((itm) => (itm.type !== 'Alliance Member' && itm.type !== 'Primary Organization'
                    ? (
                      <div className="orglist-card">
                        <div className="orgname other-center">
                          {itm.name}
                        </div>
                        <div className="orgname parent-institution">
                          {itm.parent_name ? itm.parent_name : '-'}
                        </div>
                        <div className="orgname is-alliance">
                          {itm.alliance_type === '' ? 'No' : itm.alliance_type === 'AM' ? 'Yes' : '-'}
                        </div>
                        <div className="orgname org-select-btn">
                          <Button
                            onClick={
                              () => {
                                setConfirmationModal(true);
                                setCenterSelected(itm);
                              }
                            }
                            disabled={orgChangeModalData.siteId === itm.id}
                          >
                            {orgChangeModalData.siteId === itm.id ? 'Selected' : 'Select Center'}
                          </Button>
                        </div>
                      </div>
                    )
                    : null))}
                </div>
              )
            }
            {
              listOfCenters.length === 0 && <div className="no-data-found-msg ">NO relavant data found</div>
            }

          </div>
          <div className="orglist-note">
            Note: If you do not see the required center, please change investigator profile to include desired center in the list of Other research centers
          </div>
        </Loader>
      </Modal>
      {confirmationModal && (
        <Modal
          visible={confirmationModal}
          title="Unsaved changes"
          onCancel={
            () => {
              setConfirmationModal(false);
              setCenterSelected({})
            }
          }
          footer={null}
          className="unsaved-changes-modal"
        >
          <div className="unsave-changes-modal-main">
            <div className="unsave-changes-modal-text">This action results in modifying the hierarchy of project results. Hence, the change will be saved immediately. Do you want to proceed ?</div>
            <div className="footer-modal-content">
              <Button
                className="unsave-changes-modal-discard-btn"
                type="primary"
                onClick={
                  () => {
                    setConfirmationModal(false);
                    setCenterSelected({})
                  }
                }
              >No
              </Button>
              <Button type="primary" className="save-changes-modal-discard-btn" onClick={() => selectCenterClicked()}>Yes</Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default OrgChangeModal
