import React, { useState } from 'react';
import MedidataMetrics from '../MedidataMetrics';
import PurpleLabDataForCenter from '../PurpleLabDataForCenter'
import CenterCompliance from '../CenterCompliance';

const QualityMetricsForCenters = ({ complianceData }) => {
  const [currentTab, setCurrentTab] = useState('hco_quality')
  const tagsData = [
    {
      additionalClass: '',
      key: 'hco_quality',
      name: 'HCO Quality',
      title: '',
    },
    {
      additionalClass: '',
      key: 'medidatametrices',
      name: 'Trial Metrics',
      title: '',
    },
    {
      additionalClass: '',
      key: 'compliance',
      name: 'Audit and Compliance',
      title: '',
    },
  ]

  const renderActiveContent = () => {
    switch (currentTab) {
      case 'medidatametrices': {
        return (
          <div>
            <MedidataMetrics />
          </div>
        )
      }
      case 'hco_quality': {
        return (
          <div>
            <PurpleLabDataForCenter />
          </div>
        )
      }
      case 'compliance': {
        return (
          <div>
            <CenterCompliance complianceData={complianceData} />
          </div>
        )
      }
      default: return null
    }
  }
  return (
    <>
      <div className="details-page-tabs country-project-tabs-list-tab">
        <div className="searbar-tabs center-deep-dive">
          {tagsData.map((tabItm, index) => (
            <div
              className={`tagsbutton ${currentTab === tabItm.key ? 'active' : ''} ${tabItm.additionalClass}`}
              role="presentation"
              onClick={() => {
                if (currentTab !== tabItm.key && tabItm.additionalClass.indexOf('disabled') === -1) {
                  setCurrentTab(tabItm.key);
                }
              }}
              title={tabItm.title}
              key={index}
            >
              <div className="tab-title">
                {tabItm.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        {renderActiveContent()}
      </div>
    </>
  )
}

export default QualityMetricsForCenters
