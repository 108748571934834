import React from 'react';
import { Button, Select, Input } from 'antd';

export default function RenderFilterNoOfPIS(props) {
  const {
    data, idx, setData, handleAdd, handleMinus,
  } = props;

  const handleChange = (e, type) => {
    const re = /^[0-9\b]+$/;
    if (type === 'value' && re.test(e.target.value)) {
      setData((old) => {
        const temp = { ...old };
        let value = '';
        if (type === 'value') value = e.target.value;
        else value = e;
        temp.total_number_of_pis[idx][type] = value;
        temp.total_number_of_pis[idx].field = 'total_number_of_popis'
        return temp;
      });
    } else if (type === 'compare') {
      setData((old) => {
        const temp = { ...old };
        let value = '';
        if (type === 'value') value = e.target.value;
        else value = e;
        temp.total_number_of_pis[idx][type] = value;
        temp.total_number_of_pis[idx].field = 'total_number_of_popis'
        return temp;
      });
    }
  };

  const getValue = (type) => {
    return data.total_number_of_pis[idx][type];
  };

  const onSwitchChange = (index) => {
    setData((old) => {
      const temp = JSON.parse(JSON.stringify(old));
      const oldOperator = temp.total_number_of_pis[index].operator;
      if (oldOperator === 'AND') {
        temp.total_number_of_pis[index].operator = 'OR';
      } else {
        temp.total_number_of_pis[index].operator = 'AND';
      }
      return temp;
    });
  };
  return (
    <div className="race-inner-wrap" key={idx}>
      {getValue('operator') && (
        <div className="value-wrap after-plus-content">
          <Button onClick={() => onSwitchChange(idx)}>
            {getValue('operator')}
          </Button>
        </div>
      )}
      <div className="group-input-fields">
        <div className="value-wrap" style={{ display: 'none' }}>
          <Input
            value="total_number_of_popis"
          />
        </div>
        <div className="value-wrap">
          <Select
            style={{
              width: 120,
            }}
            placeholder="Please select"
            options={[
              {
                value: 'lt',
                label: 'is less than',
              },
              {
                value: 'gt',
                label: 'is greater than',
              },
              {
                value: 'eq',
                label: 'is equal to',
              },
            ]}
            value={getValue('compare')}
            onChange={(e) => handleChange(e, 'compare')}
          />
        </div>
        <div className="value-wrap">
          <Input
            placeholder="No. of PIs"
            value={getValue('value')}
            onChange={(e) => handleChange(e, 'value')}
          />
        </div>
        <div className="value-wrap">
          <div style={{ display: 'flex' }}>
            <Button className="plus-btn" onClick={() => handleAdd(idx, 'total_number_of_pis')}>
              +
            </Button>
            {data.total_number_of_pis.length > 1 && (
            <Button
              className="plus-btn"
              onClick={() => handleMinus(idx, 'total_number_of_pis')}
            >
              -
            </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
