import React from 'react'
import {
  Button,
  DatePicker,
  Form, Input, Radio, Space, Select,
} from 'antd'

import moment from 'moment';
import { getCookie } from '../../../utils';

const { Option } = Select

export default function PatientAccess({ data }) {
  const onDateChange = (date, dateString, keyName) => {
    data.setCategoryData((oldState) => {
      const temp = {};
      temp[keyName] = dateString;
      return { ...oldState, ...temp };
    });
    return date
  };

  const getValue = (keyName) => {
    return data.categoryData[keyName];
  };

  const onCenterStateChange = (e, keyName) => {
    const { value } = e.target;
    data.setCategoryData((oldState) => {
      const temp = {};
      temp[keyName] = value;
      return { ...oldState, ...temp };
    });
  };

  const showLabelOptions = (itm) => {
    const { child_queston } = itm
    if (child_queston.length) {
      return child_queston?.map((child) => {
        const itemChild = Object.keys(data.questionsData)
          .map((res) => data.questionsData[res]).find((o) => o.id === child)
        return itemChild && renderQuesAns(itemChild)
      })
    }
    return ''
  }

  const convertDate = (getId) => {
    if (getValue(getId)) {
      const visitDate = moment(getValue(getId)).format('YYYY-MM-DD')
      return moment(visitDate, 'YYYY-MM-DD')
    }
    return undefined
  }

  const onCenterStateChangeForSelect = (e, keyName) => {
    data.setCategoryData((oldState) => {
      const temp = {};
      temp[keyName] = e;
      return { ...oldState, ...temp };
    });
  };

  const dateFormat = 'YYYY-MM-DD'

  const renderQuesAns = (itm) => {
    return (
      <Form layout="vertical" className={getCookie('permissions').includes('super_admin') && data.flag === 'admin' ? 'disable-pi-form' : null}>
        <div className="head-wrapper">
          <div className="inner-card">

            <div className="question-card">
              <Form.Item
                label={itm.question_text}
                className="question-wrapper"
              >
                {itm.type === 'single_select' ? (
                  <>
                    <Radio.Group onChange={(e) => onCenterStateChange(e, itm.id)} value={getValue(itm.id)}>
                      <Space direction="vertical">
                        {itm.options.map((j) => {
                          return <Radio value={j.option_text}>{j.option_text}</Radio>
                        })}
                      </Space>
                    </Radio.Group>
                    {showOptions(itm)}
                  </>
                ) : itm.type === 'multi_select' ? (
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please select"
                    value={getValue(itm.id)}
                    onChange={(e) => onCenterStateChangeForSelect(e, itm.id)}
                  >
                    {itm.options.map((itmm) => {
                      return <Option key={itmm.option_text}>{itmm.option_text}</Option>;
                    })}
                  </Select>

                )

                  : itm.type === 'text' ? (
                    <>
                      <Input
                        className="input-wrapper"
                        placeholder="Please enter"
                        value={getValue(itm.id)}
                      // defaultValue={getValue(itm.id)}
                        onChange={(e) => onCenterStateChange(e, itm.id)}
                      />
                      {showOptions(itm)}
                    </>
                  ) : itm.type === 'file_upload' ? (
                    <Button type="primary" disabled>Upload here</Button>
                  ) : itm.type === 'date' ? (
                    <Space direction="vertical">
                      <DatePicker
                        onChange={(date, dateString) => onDateChange(date, dateString, itm.id)}
                        value={convertDate(itm.id)}
                        allowClear={false}
                        format={dateFormat}
                        placeholder={dateFormat}
                      />
                    </Space>
                  ) : itm.type === 'label' ? showLabelOptions(itm) : ''}
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    )
  }

  const showOptions = (itm) => {
    const { options } = itm
    if (options.length) {
      return options.map((opt) => {
        if (getValue(itm.id) === opt.option_text) {
          const childQue = opt.child_questions
          return childQue?.map((child) => {
            const itemChild = Object.keys(data.questionsData)
              .map((res) => data.questionsData[res]).find((o) => o.id === child)
            return itemChild && renderQuesAns(itemChild)
          })
        }
        return ''
      })
    }
    return ''
  }

  return (
    <div>
      <div className={getCookie('permissions').includes('super_admin') && data.flag === 'admin' ? 'disable-pi' : null}>
        {data.questionsData && data.questionsData.filter((itm) => (itm.parent_id === 0))
          .sort((a, b) => a.sr_no - b.sr_no)
          .map((itm) => {
            const childQues = itm.child_queston
            if (childQues.length) {
              childQues.map((child) => {
                return renderQuesAns(data.questionsData?.find((o) => o.id === child))
              })
            }
            return renderQuesAns(itm)
          })}
      </div>
    </div>
  )
}
