import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_PI_QUES = 'GET_PI_QUES'
const GET_PI_QUES_SUCCESS = 'GET_PI_QUES_SUCCESS'
const GET_PI_QUES_FAILURE = 'GET_PI_QUES_FAILURE'
const GET_PI_QUES_RESET = 'GET_PI_QUES_RESET'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getPiQuesDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PI_QUES,
      url: `${process.env.apiUrl}v0/search/piQuestions`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getPiQuesDataResetAction = () => ({
  type: GET_PI_QUES_RESET,
})

export function getPiQuesDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PI_QUES: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PI_QUES_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_PI_QUES_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_PI_QUES_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
