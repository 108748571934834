import React, {
  useEffect, useMemo, useRef, useState,
} from 'react'
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';

import { getautosuggestionResetAction, getautosuggestvalueAction } from '../AddProjectName/logic';

export default function AutoSuggest({
  fetchOptions, debounceTimeout = 800, Value, handleChange, ...props
}) {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const autosuggestdata = useSelector((store) => store.autosuggestdata);
  const { Option } = Select;
  useEffect(() => {
    if (autosuggestdata.flag) {
      const resp = autosuggestdata.data.data.map((itm) => {
        return (
          { label: itm.key, value: itm.value }
        )
      })
      setOptions(resp)
      setFetching(false);
    }
  }, [JSON.stringify(autosuggestdata)])

  const onIndicationChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'indications',
          dataset: 'queries',
        }),
      )
    }
  }

  const onSpecialityChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'specialities',
          dataset: 'queries',
        }),
      )
    }
  }

  const debounceFetcher = useMemo(() => {
    const loadOptions = (v) => {
      fetchRef.current += 1;
      setOptions([]);
      setFetching(true);
      if (props.flag === 'indications') {
        onIndicationChangeInput(v)
      } else {
        onSpecialityChangeInput(v)
      }
    }

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      value={Value?.value?.map((itm) => { return { label: itm.key, value: itm.value } })}
      {...props}
      onChange={handleChange}
      onFocus={() => {
        dispatch(getautosuggestionResetAction())
        setOptions([]);
      }}
    >
      {options.map((itm) => {
        return (
          <Option value={itm.value} />
        )
      })}
    </Select>
  );
}
