import React, { useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  Form, Input, Modal, Radio, Select,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { getCookie } from '../../../utils'

export default function PIComplianceIssues({ data }) {
  const userId = getCookie('userName')
  const dateFormat = 'YYYY-MM-DD'
  const columns = [
    {
      label: '',
      key: 'action',
      lengthTen: true,
    },
    {
      label: 'Description',
      key: 'compliance_issue_desc',
    },
    {
      label: 'Compliance Preventing Participation',
      key: 'no_trial_participation_flag',
      lengthTen: true,
    },
    {
      label: 'Compliance Issue Comment',
      key: 'compliance_issue_comment',
    },
    {
      label: 'Compliance Issue Raised Date',
      key: 'compliance_issue_raised_dt',
      lengthTen: true,
    },
    {
      label: 'Reviewed Status',
      key: 'review_status',
      lengthTen: true,
    },
    {
      label: 'Review Date',
      key: 'review_date',
      lengthTen: true,
    },
    {
      label: 'Reviewer Name',
      key: 'reviewer_name',
      lengthTen: true,
    },
    {
      label: 'Re-evaluation Comment',
      key: 'reevaluation_comment',
    },
  ]

  const cdd_columns = [
    {
      label: 'Due Diligence Status',
      key: 'status',
    },
    {
      label: 'Due Diligence Date',
      key: 'date',
      lengthTen: true,
    },
    {
      label: 'Due Diligence Comment',
      key: 'comment',
    },
  ]
  const YesFlagReviewStatusList = [
    {
      key: 'Retain',
      value: 'Retain',
    },
    {
      key: 'Upgrade to GCP Non-compliant',
      value: 'Upgrade to GCP Non-compliant',
    },
    {
      key: 'Downgrade to PAREXEL Warning',
      value: 'Downgrade to PAREXEL Warning',
    },
    {
      key: 'Invalid',
      value: 'Invalid',
    },
  ]

  const cdd_status = [
    {
      key: 'Approved',
      value: 'Approved',
    },
    {
      key: 'Approved with Warning',
      value: 'Approved with Warning',
    },
    {
      key: 'DNU',
      value: 'DNU',
    },
  ]

  const { Option } = Select;
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
  })
  const [complianceDesc, setComplianceDesc] = useState([])
  const [addCompliance, setAddCompliance] = useState(false)
  const getCompliancePiFilterResponse = useSelector((store) => store.getPiFilterDataResponse);
  const [newCompliance, setNewCompliance] = useState({
    no_trial_participation_flag: undefined,
    // fraud_misconduct_code: undefined,
    compliance_issue_desc: undefined,
    compliance_issue_comment: undefined,
    compliance_issue_raised_dt: undefined,
    review_status: undefined,
    // valid_flag: true,
    review_date: undefined,
    reviewer_name: undefined,
    reevaluation_comment: undefined,
  })

  const [editCompliance, setEditCompliance] = useState({
    no_trial_participation_flag: undefined,
    // fraud_misconduct_code: undefined,
    compliance_issue_desc: undefined,
    compliance_issue_comment: undefined,
    compliance_issue_raised_dt: undefined,
    review_status: undefined,
    // valid_flag: true,
    review_date: undefined,
    reviewer_name: undefined,
    reevaluation_comment: undefined,
  })
  const [filterData, setFilterData] = useState({})

  const [keyForDeleteCompliance, setKeyForDeleteCompliance] = useState('')
  const [indexForCompliance, setIndexForCompliance] = useState()
  const [deleteComplianceModal, setDeleteComplianceModal] = useState(false)

  useEffect(() => {
    if (getCompliancePiFilterResponse.flag) {
      setComplianceDesc(getCompliancePiFilterResponse.data.data.compliance_issue_desc)
      setFilterData(getCompliancePiFilterResponse.data.data.compliance_code_and_prevention_flag)
    }
  }, [JSON.stringify(getCompliancePiFilterResponse)])

  useEffect(() => {
    if (!data.cddData || data?.cddData?.length === 0) {
      data.setCddData([{
        status: '',
        prevention_flag: '',
        date: '',
        comment: '',
      }])
    }
  }, [])
  const handleDescriptionChange = (e) => {
    setNewCompliance({
      ...newCompliance,
      compliance_issue_desc: e,
      no_trial_participation_flag: filterData[e]?.prevention_flag ? filterData[e]?.prevention_flag : undefined,
      // fraud_misconduct_code: filterData[e]?.code,
      review_status: undefined,
    })
  }

  const handleDescriptionChangeEdit = (e) => {
    setEditCompliance({
      ...editCompliance,
      compliance_issue_desc: e,
      no_trial_participation_flag: filterData[e]?.prevention_flag ? filterData[e]?.prevention_flag : undefined,
      // fraud_misconduct_code: filterData[e]?.code,
      // review_status: undefined,
    })
  }

  const handleAddNewCompliance = () => {
    data.setComplianceIssues([
      ...data.complianceIssues,
      {
        no_trial_participation_flag: newCompliance.no_trial_participation_flag ? newCompliance.no_trial_participation_flag : undefined,
        compliance_issue_desc: newCompliance.compliance_issue_desc ? newCompliance.compliance_issue_desc : undefined,
        // fraud_misconduct_code: newCompliance.fraud_misconduct_code ? newCompliance.fraud_misconduct_code : undefined,
        compliance_issue_comment: newCompliance.compliance_issue_comment ? newCompliance.compliance_issue_comment : undefined,
        compliance_issue_raised_dt: newCompliance.compliance_issue_raised_dt ? newCompliance?.compliance_issue_raised_dt?.format(dateFormat) : undefined,
        review_status: newCompliance.review_status ? newCompliance.review_status : undefined,
        // valid_flag: newCompliance.valid_flag,
        review_date: newCompliance.review_date ? newCompliance?.compliance_issue_raised_dt?.format(dateFormat) : undefined,
        reviewer_name: newCompliance.reviewer_name ? newCompliance.reviewer_name : undefined,
        reevaluation_comment: newCompliance.reevaluation_comment ? newCompliance.reevaluation_comment : undefined,
      }]);
    setNewCompliance({
      no_trial_participation_flag: undefined,
      compliance_issue_desc: undefined,
      // fraud_misconduct_code: undefined,
      compliance_issue_comment: undefined,
      compliance_issue_raised_dt: undefined,
      review_status: undefined,
      // valid_flag: true,
      review_date: undefined,
      reviewer_name: undefined,
      reevaluation_comment: undefined,
    })
    setAddCompliance(false)
  }

  const onSaveEditClick = () => {
    const temp = data.complianceIssues
    const obj = {
      no_trial_participation_flag: editCompliance.no_trial_participation_flag ? editCompliance.no_trial_participation_flag : undefined,
      compliance_issue_desc: editCompliance.compliance_issue_desc ? editCompliance.compliance_issue_desc : undefined,
      // fraud_misconduct_code: editCompliance.compliance_issue_desc ? editCompliance.compliance_issue_desc : undefined,
      compliance_issue_comment: editCompliance.compliance_issue_comment ? editCompliance.compliance_issue_comment : undefined,
      compliance_issue_raised_dt: editCompliance.compliance_issue_raised_dt ? editCompliance.compliance_issue_raised_dt?.format(dateFormat) : undefined,
      review_status: editCompliance.review_status ? editCompliance.review_status : undefined,
      // valid_flag: editCompliance.valid_flag,
      review_date: editCompliance.review_date ? editCompliance.review_date?.format(dateFormat) : undefined,
      reviewer_name: editCompliance.reviewer_name ? editCompliance.reviewer_name : undefined,
      reevaluation_comment: editCompliance.reevaluation_comment ? editCompliance.reevaluation_comment : undefined,
    }
    temp[editing.editingId] = obj
    data.setComplianceIssues(temp)
    setEditing({ isEditing: false, editingId: '' })
  }

  const onDeleteCompliance = (idx, key) => {
    setEditing({ isEditing: true, editingId: idx });
    setKeyForDeleteCompliance(key)
    setIndexForCompliance(idx)
    setDeleteComplianceModal(true)
  }

  const onDeleteComplianceApi = () => {
    const temp = data.complianceIssues
    if (keyForDeleteCompliance === 'Frompage') {
      const recentDeleted = temp.filter((itm, idx1) => idx1 !== indexForCompliance);
      data.setComplianceIssues(recentDeleted)
      setEditing({ isEditing: false, editingId: '' })
      setDeleteComplianceModal(false)
    } else {
      const recentDeleted = temp.filter((itm, idx1) => idx1 === indexForCompliance);
      const data1 = recentDeleted[0]
      const obj = {
        no_trial_participation_flag: data1.no_trial_participation_flag ? data1.no_trial_participation_flag : undefined,
        compliance_issue_desc: data1.compliance_issue_desc ? data1.compliance_issue_desc : undefined,
        // fraud_misconduct_code: data1.compliance_issue_desc ? data1.compliance_issue_desc : undefined,
        compliance_issue_comment: data1.compliance_issue_comment ? data1.compliance_issue_comment : undefined,
        compliance_issue_raised_dt: data1.compliance_issue_raised_dt !== '' ? data1.compliance_issue_raised_dt : undefined,
        review_status: data1.review_status ? data1.review_status : undefined,
        // valid_flag: data1.valid_flag,
        review_date: data1?.review_date !== '' ? data1?.review_date : undefined,
        reviewer_name: data1.reviewer_name ? data1.reviewer_name : undefined,
        reevaluation_comment: data1.reevaluation_comment ? data1.reevaluation_comment : undefined,
        delete_flag: true,
      }
      // data.setComplianceIssues(...data.complianceIssues, obj)
      temp[indexForCompliance] = obj
      data.setComplianceIssues(temp)
      setEditing({ isEditing: false, editingId: '' })
      setDeleteComplianceModal(false)
    }
  }

  return (
    <div className={(userId === data.checkUserId && data.checkStatus === 'Send_for_clarification') || data.flag !== 'admin' ? 'compliance-page-wrapper compliance' : 'compliance-page-wrapper compliance disable-pi'}>
      <div className="compliance-page">
        <Form
          layout="vertical"
          name="piform"
          className={(userId === data.checkUserId && data.checkStatus === 'Send_for_clarification') || data.flag !== 'admin'
            ? null
            : 'disable-pi-form'}
        >
          <div className="section-inner-title">Compliance Due Diligence</div>
          <div className="license-card">
            <div className="license-table-main">
              <div id="table-column-header" className="license-table-header">
                {cdd_columns.map((type) => (
                  <div className={`license-table-header-item ${type.lengthTen ? 'length-ten' : 'length-fourty'}`}>
                    {type.label}
                  </div>
                ))}
              </div>
              <div className="license-table-body table-column-body">
                <div className="license-table-row table-column-body">
                  <div className="license-table-row-itm length-fourty">
                    <Select
                      className="input-wrapper"
                      placeholder="Select CDD Status"
                      value={data.cddData[0]?.status}
                      onChange={(e) => {
                        data.setCddData([{ ...data.cddData[0], status: e, prevention_flag: e === 'DNU' ? 'Y' : e === 'Approved with Warning' ? 'N' : '' }])
                      }}
                      options={cdd_status}
                    />
                  </div>
                  <div className="license-table-row-itm length-ten">
                    <DatePicker
                      value={data.cddData[0]?.date && moment(data.cddData[0]?.date, dateFormat)}
                      format={dateFormat}
                      onChange={(e) => {
                        data.setCddData([{ ...data.cddData[0], date: e.format(dateFormat) }])
                      }}
                    />
                  </div>
                  <div className="license-table-row-itm length-fourty">
                    <TextArea
                      rows={1}
                      minRows={5}
                      value={data.cddData[0]?.comment}
                      onChange={(e) => {
                        data.setCddData([{ ...data.cddData[0], comment: e.target.value }])
                      }}
                    />
                  </div>
                  <div className="license-table-row-itm length-ten">
                    <Button
                      onClick={() => {
                        data.setCddData([{
                          status: '',
                          prevention_flag: '',
                          date: '',
                          comment: '',
                        }])
                      }}
                      disabled={data.cddData.length && (data.cddData[0]?.comment === '' && data.cddData[0]?.date === '' && data.cddData[0]?.status === '')}
                      className="cancel-btn"
                      title="Cancel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-inner-title">GCP Compliance</div>
          <div className="license-card">
            <div className="license-table-main">
              <div id="table-column-header" className="license-table-header">
                {columns.map((type) => (
                  <div className={`license-table-header-item ${type.lengthTen ? 'length-ten' : 'length-twentyfive'}`}>
                    {type.label}
                  </div>
                ))}
              </div>
              <div className="license-table-body table-column-body">
                {data?.complianceIssues?.map((record, index) => {
                  return editing.isEditing && editing.editingId === index ? (
                    <>
                      <div className="license-table-row table-column-body">
                        <div className="license-table-row-itm length-ten">
                          <Button
                            disabled={editCompliance.compliance_issue_desc === '' || editCompliance.compliance_issue_desc === undefined}
                            onClick={() => onSaveEditClick()}
                            className="save-btn"
                            title="Save"
                          />
                          <Button
                            onClick={() => {
                              setEditCompliance({
                                no_trial_participation_flag: undefined,
                                compliance_issue_desc: undefined,
                                // fraud_misconduct_code: undefined,
                                compliance_issue_comment: undefined,
                                compliance_issue_raised_dt: undefined,
                                review_status: undefined,
                                // valid_flag: true,
                                review_date: undefined,
                                reviewer_name: undefined,
                                reevaluation_comment: undefined,
                              });
                              setEditing({ isEditing: false, editingId: '' });
                            }}
                            className="cancel-btn"
                            title="Cancel"
                          />
                        </div>
                        <div className="license-table-row-itm length-twentyfive">
                          <Select
                            className="input-wrapper"
                            placeholder="Select description"
                            value={editCompliance.compliance_issue_desc}
                            onChange={(e) => handleDescriptionChangeEdit(e)}
                          // onChange={(e) => handleCheckboxChange(e, 'description')}
                          >
                            {complianceDesc && complianceDesc.map((itm) => {
                              return <Option value={itm.value}>{itm.key}</Option>
                            })}
                          </Select>
                        </div>
                        <div className="license-table-row-itm length-ten">
                          <Radio.Group value={editCompliance.no_trial_participation_flag} disabled>
                            <Radio value="Y">Yes</Radio>
                            <Radio value="N">No</Radio>
                          </Radio.Group>
                        </div>
                        <div className="license-table-row-itm length-twentyfive">
                          <TextArea rows={1} minRows={5} value={editCompliance.compliance_issue_comment} onChange={(e) => e.target.value !== ' ' && setEditCompliance({ ...editCompliance, compliance_issue_comment: e.target.value })} />
                        </div>
                        <div className="license-table-row-itm length-ten">
                          <DatePicker value={editCompliance.compliance_issue_raised_dt} format={dateFormat} onChange={(e) => setEditCompliance({ ...editCompliance, compliance_issue_raised_dt: e })} />
                        </div>
                        <div className="license-table-row-itm length-ten">
                          <Select
                            className="input-wrapper"
                            placeholder="Select status"
                            value={editCompliance.review_status}
                            onChange={(e) => setEditCompliance({ ...editCompliance, review_status: e })}
                            disabled={editing.isEditing === false || editCompliance.no_trial_participation_flag === undefined || editCompliance.no_trial_participation_flag === null || data.checkStatus === 'Send_for_clarification'}
                            options={YesFlagReviewStatusList}
                            // options={editCompliance.no_trial_participation_flag === 'Y' ? YesFlagReviewStatusList : NoFlagReviewStatusList}
                          />
                        </div>
                        {/* <div className="license-table-row-itm length-ten">
                          <Radio.Group value={editCompliance.valid_flag} onChange={(e) => setEditCompliance({ ...editCompliance, valid_flag: e.target.value })}>
                            <Radio value>Valid</Radio>
                            <Radio value={false}>Invalid</Radio>
                          </Radio.Group>
                        </div> */}
                        <div className="license-table-row-itm length-ten">
                          <DatePicker value={editCompliance.review_date} format={dateFormat} onChange={(e) => setEditCompliance({ ...editCompliance, review_date: e })} disabled={data.checkStatus === 'Send_for_clarification'} />
                        </div>
                        <div className="license-table-row-itm length-ten">
                          <Input value={editCompliance.reviewer_name} onChange={(e) => setEditCompliance({ ...editCompliance, reviewer_name: e.target.value })} disabled={data.checkStatus === 'Send_for_clarification'} />
                        </div>
                        <div className="license-table-row-itm length-twentyfive">
                          <TextArea rows={1} minRows={5} value={editCompliance.reevaluation_comment} onChange={(e) => e.target.value !== ' ' && setEditCompliance({ ...editCompliance, reevaluation_comment: e.target.value })} disabled={data.checkStatus === 'Send_for_clarification'} />
                        </div>
                      </div>
                    </>
                  )
                    : data.flag !== 'edit-admin' && record.delete_flag !== true
                      ? (
                        <>
                          <div className="license-table-row table-column-body">
                            <div className="license-table-row-itm length-ten">
                              <Button
                                onClick={() => {
                                  setEditCompliance({
                                    no_trial_participation_flag: record.no_trial_participation_flag,
                                    compliance_issue_desc: record.compliance_issue_desc ? record.compliance_issue_desc : undefined,
                                    // fraud_misconduct_code: record.fraud_misconduct_code ? record.fraud_misconduct_code : undefined,
                                    compliance_issue_comment: record.compliance_issue_comment ? record.compliance_issue_comment : undefined,
                                    compliance_issue_raised_dt: record.compliance_issue_raised_dt ? moment(record.compliance_issue_raised_dt, dateFormat) : undefined,
                                    review_status: record.review_status ? record.review_status : undefined,
                                    // valid_flag: record.valid_flag,
                                    review_date: record.review_date ? moment(record.review_date, dateFormat) : undefined,
                                    reviewer_name: record.reviewer_name ? record.reviewer_name : undefined,
                                    reevaluation_comment: record.reevaluation_comment ? record.reevaluation_comment : undefined,
                                  })
                                  setEditing({ isEditing: true, editingId: index });
                                }}
                                className="edit-btn"
                                title="Edit"
                              />
                              {
                          (data.flag === 'fromEditPI' || data.flag === 'admin') && (
                            <>
                              <div className="edit-delete-button">
                                <Button
                                  onClick={() => {
                                    setEditCompliance({
                                      no_trial_participation_flag: record.no_trial_participation_flag,
                                      compliance_issue_desc: record.compliance_issue_desc ? record.compliance_issue_desc : undefined,
                                      // fraud_misconduct_code: record.fraud_misconduct_code ? record.fraud_misconduct_code : undefined,
                                      compliance_issue_comment: record.compliance_issue_comment ? record.compliance_issue_comment : undefined,
                                      compliance_issue_raised_dt: record.compliance_issue_raised_dt ? moment(record.compliance_issue_raised_dt, dateFormat) : undefined,
                                      review_status: record.review_status ? record.review_status : undefined,
                                      // valid_flag: record.valid_flag,
                                      review_date: record.review_date ? moment(record.review_date, dateFormat) : undefined,
                                      reviewer_name: record.reviewer_name ? record.reviewer_name : undefined,
                                      reevaluation_comment: record.reevaluation_comment ? record.reevaluation_comment : undefined,
                                    })
                                    onDeleteCompliance(index, 'fromapi')
                                  }}
                                  className="del-icon-btn"
                                  type="primary"
                                />
                              </div>
                            </>

                          )
                        }
                              {
                          data.flag === 'fromMainPage' && (
                            <>
                              <div className="edit-delete-button">
                                <Button
                                  onClick={() => {
                                    setEditCompliance({
                                      no_trial_participation_flag: record.no_trial_participation_flag,
                                      compliance_issue_desc: record.compliance_issue_desc ? record.compliance_issue_desc : undefined,
                                      // fraud_misconduct_code: record.fraud_misconduct_code ? record.fraud_misconduct_code : undefined,
                                      compliance_issue_comment: record.compliance_issue_comment ? record.compliance_issue_comment : undefined,
                                      compliance_issue_raised_dt: record.compliance_issue_raised_dt ? moment(record.compliance_issue_raised_dt, dateFormat) : undefined,
                                      review_status: record.review_status ? record.review_status : undefined,
                                      // valid_flag: record.valid_flag,
                                      review_date: record.review_date ? moment(record.review_date, dateFormat) : undefined,
                                      reviewer_name: record.reviewer_name ? record.reviewer_name : undefined,
                                      reevaluation_comment: record.reevaluation_comment ? record.reevaluation_comment : undefined,
                                    })
                                    onDeleteCompliance(index, 'Frompage')
                                  }}
                                  className="del-icon-btn"
                                  type="primary"
                                />
                              </div>
                            </>
                          )
                        }

                            </div>
                            <div className="license-table-row-itm length-twentyfive">
                              {record.compliance_issue_desc ? record.compliance_issue_desc : '-'}
                            </div>
                            <div className="license-table-row-itm length-ten">
                              {record.no_trial_participation_flag === 'Y' ? 'Yes' : record.no_trial_participation_flag === 'N' ? 'No' : '-'}
                            </div>
                            {/* <div className="license-table-row-itm length-ten">
                        {record.fraud_misconduct_code ? record.fraud_misconduct_code : '-'}
                      </div> */}
                            <div className="license-table-row-itm length-twentyfive">
                              {record.compliance_issue_comment ? record.compliance_issue_comment : '-'}
                            </div>
                            <div className="license-table-row-itm length-ten">
                              {record.compliance_issue_raised_dt ? record.compliance_issue_raised_dt : '-'}
                            </div>
                            <div className="license-table-row-itm length-ten">
                              {record.review_status ? record.review_status : '-'}
                            </div>
                            {/* <div className="license-table-row-itm length-ten">
                        {record.valid_flag === true ? 'Valid' : record.valid_flag === false ? 'Invalid' : '-'}
                      </div> */}
                            <div className="license-table-row-itm length-ten">
                              {record.review_date ? record.review_date : '-'}
                            </div>
                            <div className="license-table-row-itm length-ten">
                              {record.reviewer_name ? record.reviewer_name : '-'}
                            </div>
                            <div className="license-table-row-itm length-twentyfive">
                              {record.reevaluation_comment ? record.reevaluation_comment : '-'}
                            </div>
                          </div>
                        </>
                      ) : data.flag === 'edit-admin' && record.delete_flag.new !== true ? (
                        <>
                          <div className="license-table-row table-column-body">
                            <div className="license-table-row-itm length-ten">
                              <Button
                                className="edit-btn"
                                title="Edit"
                              />

                              <div className="edit-delete-button">
                                <Button
                                  className="del-icon-btn"
                                  type="primary"
                                />
                              </div>

                            </div>
                            <div className="license-table-row-itm length-twentyfive">
                              {record.compliance_issue_desc.new ? record.compliance_issue_desc.new : '-'}
                            </div>
                            <div className="license-table-row-itm length-ten">
                              {record.no_trial_participation_flag.new === 'Y' ? 'Yes' : record.no_trial_participation_flag.new === 'N' ? 'No' : '-'}
                            </div>

                            <div className="license-table-row-itm length-twentyfive">
                              {record.compliance_issue_comment.new ? record.compliance_issue_comment.new : '-'}
                            </div>
                            <div className="license-table-row-itm length-ten">
                              {record.compliance_issue_raised_dt.new ? record.compliance_issue_raised_dt.new : '-'}
                            </div>
                            <div className="license-table-row-itm length-ten">
                              {record.review_status.new ? record.review_status.new : '-'}
                            </div>

                            <div className="license-table-row-itm length-ten">
                              {record.review_date.new ? record.review_date.new : '-'}
                            </div>
                            <div className="license-table-row-itm length-ten">
                              {record.reviewer_name.new ? record.reviewer_name.new : '-'}
                            </div>
                            <div className="license-table-row-itm length-twentyfive">
                              {record.reevaluation_comment.new ? record.reevaluation_comment.new : '-'}
                            </div>
                          </div>
                        </>

                      ) : ''
                })}
                {
                  !editing.isEditing && addCompliance && (
                    <>
                      <>
                        <div className="license-table-row table-column-body">
                          <div className="license-table-row-itm length-ten">
                            <Button
                              disabled={newCompliance.compliance_issue_desc === '' || newCompliance.compliance_issue_desc === undefined}
                              onClick={() => {
                                if (newCompliance.compliance_issue_desc !== '' || newCompliance.compliance_issue_desc === undefined) {
                                  handleAddNewCompliance()
                                }
                              }}
                              className="save-btn"
                            />
                            <Button
                              disabled={newCompliance.compliance_issue_desc === ''}
                              onClick={() => {
                                setNewCompliance({
                                  no_trial_participation_flag: undefined,
                                  compliance_issue_desc: undefined,
                                  // fraud_misconduct_code: undefined,
                                  compliance_issue_comment: undefined,
                                  compliance_issue_raised_dt: undefined,
                                  review_status: undefined,
                                  valid_flag: true,
                                  review_date: undefined,
                                  reviewer_name: undefined,
                                  reevaluation_comment: undefined,
                                });
                                setAddCompliance(false)
                              }}
                              className="cancel-btn"
                            />
                          </div>
                          <div className="license-table-row-itm length-twentyfive">
                            <Select
                              className="input-wrapper"
                              placeholder="Select description"
                              value={newCompliance.compliance_issue_desc}
                              onChange={(e) => handleDescriptionChange(e)}
                            // onChange={(e) => handleCheckboxChange(e, 'description')}
                            >
                              {complianceDesc && complianceDesc.map((itm) => {
                                return <Option value={itm.value}>{itm.key}</Option>
                              })}
                            </Select>
                          </div>
                          <div className="license-table-row-itm length-ten">
                            <Radio.Group value={newCompliance.no_trial_participation_flag} disabled>
                              <Radio value="Y">Yes</Radio>
                              <Radio value="N">No</Radio>
                            </Radio.Group>
                          </div>
                          {/* <div className="license-table-row-itm length-ten">
                            <Select
                              className="input-wrapper"
                              value={newCompliance.fraud_misconduct_code}
                              disabled
                            />
                          </div> */}
                          <div className="license-table-row-itm length-twentyfive">
                            <TextArea rows={1} minRows={5} value={newCompliance.compliance_issue_comment} onChange={(e) => e.target.value !== ' ' && setNewCompliance({ ...newCompliance, compliance_issue_comment: e.target.value })} />
                          </div>
                          <div className="license-table-row-itm length-ten">
                            <DatePicker value={newCompliance.compliance_issue_raised_dt} format={dateFormat} onChange={(e) => setNewCompliance({ ...newCompliance, compliance_issue_raised_dt: e })} />
                          </div>
                          <div className="license-table-row-itm length-ten">
                            <Select
                              className="input-wrapper"
                              placeholder="Select status"
                              value={newCompliance.review_status}
                              onChange={(e) => setNewCompliance({ ...newCompliance, review_status: e })}
                              disabled={editing.isEditing === false || newCompliance.no_trial_participation_flag === undefined || newCompliance.no_trial_participation_flag === null}
                              options={YesFlagReviewStatusList}
                              // options={newCompliance.no_trial_participation_flag === 'Y' ? YesFlagReviewStatusList : NoFlagReviewStatusList}
                            />
                          </div>
                          {/* <div className="license-table-row-itm length-ten">
                            <Radio.Group value={newCompliance.valid_flag} disabled>
                              <Radio value>Valid</Radio>
                              <Radio value={false}>Invalid</Radio>
                            </Radio.Group>
                          </div> */}
                          <div className="license-table-row-itm length-ten">
                            <DatePicker value={newCompliance.review_date} format={dateFormat} onChange={(e) => setNewCompliance({ ...newCompliance, review_date: e })} disabled />
                          </div>
                          <div className="license-table-row-itm length-ten">
                            <Input value={newCompliance.reviewer_name} onChange={(e) => setNewCompliance({ ...newCompliance, reviewer_name: e.target.value })} disabled />
                          </div>
                          <div className="license-table-row-itm length-twentyfive">
                            <TextArea rows={1} minRows={5} value={newCompliance.reevaluation_comment} onChange={(e) => e.target.value !== ' ' && setNewCompliance({ ...newCompliance, reevaluation_comment: e.target.value })} disabled />
                          </div>
                        </div>
                      </>
                    </>
                  )
                }
                {!addCompliance && !editing.isEditing && data.flag !== 'edit-admin'
                  && (
                    <div style={{ padding: '30px', alignItems: 'center' }}>
                      <Button type="primary" onClick={() => setAddCompliance(!addCompliance)}>Add Compliance</Button>
                    </div>
                  )}
              </div>
            </div>
          </div>
          {
        deleteComplianceModal ? (
          <Modal
            open={deleteComplianceModal}
            onCancel={() => {
              setEditing({ isEditing: false, editingId: '' })
              setDeleteComplianceModal(false)
            }}
            onOk={() => onDeleteComplianceApi()}
          >
            <div>
              Do you really want to delete this compliance?
            </div>

          </Modal>
        ) : ''
      }
        </Form>
      </div>
    </div>
  )
}
