import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_HIERARCHY = 'GET_HIERARCHY'
const GET_HIERARCHY_SUCCESS = 'GET_HIERARCHY_SUCCESS'
const GET_HIERARCHY_FAILURE = 'GET_HIERARCHY_FAILURE'
const GET_HIERARCHY_RESET = 'GET_HIERARCHY_RESET'

const GET_PIS_IN_HIERARCHY = 'GET_PIS_IN_HIERARCHY'
const GET_MORE_PIS_IN_HIERARCHY = 'GET_MORE_PIS_IN_HIERARCHY'
const GET_PIS_IN_HIERARCHY_SUCCESS = 'GET_PIS_IN_HIERARCHY_SUCCESS'
const GET_MORE_PIS_IN_HIERARCHY_SUCCESS = 'GET_MORE_PIS_IN_HIERARCHY_SUCCESS'
const GET_PIS_IN_HIERARCHY_FAILURE = 'GET_PIS_IN_HIERARCHY_FAILURE'
const GET_PIS_IN_HIERARCHY_RESET = 'GET_PIS_IN_HIERARCHY_RESET'

const ADD_CHILD_CENTER_IN_HIERARCHY = 'ADD_CHILD_CENTER_IN_HIERARCHY'
const ADD_CHILD_CENTER_IN_HIERARCHY_SUCCESS = 'ADD_CHILD_CENTER_IN_HIERARCHY_SUCCESS'
const ADD_CHILD_CENTER_IN_HIERARCHY_FAILURE = 'ADD_CHILD_CENTER_IN_HIERARCHY_FAILURE'
const ADD_CHILD_CENTER_IN_HIERARCHY_RESET = 'ADD_CHILD_CENTER_IN_HIERARCHY_RESET'

const REMOVE_CHILD_CENTER_FROM_HIERARCHY = 'REMOVE_CHILD_CENTER_FROM_HIERARCHY'
const REMOVE_CHILD_CENTER_FROM_HIERARCHY_SUCCESS = 'REMOVE_CHILD_CENTER_FROM_HIERARCHY_SUCCESS'
const REMOVE_CHILD_CENTER_FROM_HIERARCHY_FAILURE = 'REMOVE_CHILD_CENTER_FROM_HIERARCHY_FAILURE'
const REMOVE_CHILD_CENTER_FROM_HIERARCHY_RESET = 'REMOVE_CHILD_CENTER_FROM_HIERARCHY_RESET'

const CHANGE_PARENT_IN_HIERARCHY = 'CHANGE_PARENT_IN_HIERARCHY'
const CHANGE_PARENT_IN_HIERARCHY_SUCCESS = 'CHANGE_PARENT_IN_HIERARCHY_SUCCESS'
const CHANGE_PARENT_IN_HIERARCHY_FAILURE = 'CHANGE_PARENT_IN_HIERARCHY_FAILURE'
const CHANGE_PARENT_IN_HIERARCHY_RESET = 'CHANGE_PARENT_IN_HIERARCHY_RESET'

const GET_PARENT_INSTITUTION_HIERARCHY = 'GET_PARENT_INSTITUTION_HIERARCHY'
const GET_PARENT_INSTITUTION_HIERARCHY_SUCCESS = 'GET_PARENT_INSTITUTION_HIERARCHY_SUCCESS'
const GET_PARENT_INSTITUTION_HIERARCHY_FAILURE = 'GET_PARENT_INSTITUTION_HIERARCHY_FAILURE'
const GET_PARENT_INSTITUTION_HIERARCHY_RESET = 'GET_PARENT_INSTITUTION_HIERARCHY_RESET'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getHierarchyAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_HIERARCHY,
      url: `${process.env.apiUrl}v0/search/getHierarchy`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getHierarchyResetAction = () => ({
  type: GET_HIERARCHY_RESET,
})

export function getHierarchyReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_HIERARCHY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_HIERARCHY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_HIERARCHY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_HIERARCHY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getPisInHierarchyAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PIS_IN_HIERARCHY,
      url: `${process.env.apiUrl}v0/search/listOfPIsInHierarchy`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getMorePisInHierarchyAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_MORE_PIS_IN_HIERARCHY,
      url: `${process.env.apiUrl}v0/search/listOfPIsInHierarchy`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getPisInHierarchyResetAction = () => ({
  type: GET_PIS_IN_HIERARCHY_RESET,
})

export const addChildCenterInHierarchyAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: ADD_CHILD_CENTER_IN_HIERARCHY,
      url: `${process.env.apiUrl}v0/search/addChildCenterInHierarchy`,
      method: 'POST',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Center added to hierarchy successfully',
    hideNotification: false, // hide notification on error
  })
}

export const addChildCenterInHierarchyResetAction = () => ({
  type: ADD_CHILD_CENTER_IN_HIERARCHY_RESET,
})

export const removeChildCenterFromHierarchyAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: REMOVE_CHILD_CENTER_FROM_HIERARCHY,
      url: `${process.env.apiUrl}v0/search/removeChildCenterInHierarchy?${stringify(payload.params)}`,
      method: 'DELETE',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Center removed from hierarchy successfully',
    hideNotification: false, // hide notification on error
  })
}

export const removeChildCenterFromHierarchyResetAction = () => ({
  type: REMOVE_CHILD_CENTER_FROM_HIERARCHY_RESET,
})

export const changeParentInHierarchyAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: CHANGE_PARENT_IN_HIERARCHY,
      url: `${process.env.apiUrl}v0/search/changeParentCenterInHierarchy`,
      method: 'PUT',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Parent institute changed in the hierarchy successfully',
    hideNotification: false, // hide notification on error
  })
}

export const changeParentInHierarchyResetAction = () => ({
  type: CHANGE_PARENT_IN_HIERARCHY_RESET,
})

export function getPisInHierarchyReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PIS_IN_HIERARCHY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PIS_IN_HIERARCHY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_MORE_PIS_IN_HIERARCHY_SUCCESS: {
      return {
        data: {
          ...action.payload,
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PIS_IN_HIERARCHY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_PIS_IN_HIERARCHY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function addChildCenterInHierarchyReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADD_CHILD_CENTER_IN_HIERARCHY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case ADD_CHILD_CENTER_IN_HIERARCHY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case ADD_CHILD_CENTER_IN_HIERARCHY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case ADD_CHILD_CENTER_IN_HIERARCHY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function removeChildCenterFromHierarchyReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case REMOVE_CHILD_CENTER_FROM_HIERARCHY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case REMOVE_CHILD_CENTER_FROM_HIERARCHY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case REMOVE_CHILD_CENTER_FROM_HIERARCHY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case REMOVE_CHILD_CENTER_FROM_HIERARCHY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function changeParentInHierarchyReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case CHANGE_PARENT_IN_HIERARCHY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CHANGE_PARENT_IN_HIERARCHY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CHANGE_PARENT_IN_HIERARCHY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CHANGE_PARENT_IN_HIERARCHY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getParentInstitutionHierarchyAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PARENT_INSTITUTION_HIERARCHY,
      url: `${process.env.apiUrl}v0/institution/getParentSiteHierarchy`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getParentInstitutionHierarchyResetAction = () => ({
  type: GET_PARENT_INSTITUTION_HIERARCHY_RESET,
})

export function getParentInstitutionHierarchyReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PARENT_INSTITUTION_HIERARCHY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PARENT_INSTITUTION_HIERARCHY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PARENT_INSTITUTION_HIERARCHY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_PARENT_INSTITUTION_HIERARCHY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
