import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../SearchResults/logic'

const LIST_OF_REQUESTS_NEW = 'LIST_OF_REQUESTS_NEW'
const LIST_OF_REQUESTS_NEW_SUCCESS = 'LIST_OF_REQUESTS_NEW_SUCCESS'
const LIST_OF_REQUESTS_NEW_RESET = 'LIST_OF_REQUESTS_NEW_RESET'
const LIST_OF_REQUESTS_NEW_FAILURE = 'LIST_OF_REQUESTS_NEW_FAILURE'

const DELETE_NEW_ADDED_SITE = 'DELETE_NEW_ADDED_SITE'
const DELETE_NEW_ADDED_SITE_SUCCESS = 'DELETE_NEW_ADDED_SITE_SUCCESS'
const DELETE_NEW_ADDED_SITE_RESET = 'DELETE_NEW_ADDED_SITE_RESET'
const DELETE_NEW_ADDED_SITE_FAILURE = 'DELETE_NEW_ADDED_SITE_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getNewListOfRequestsDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: LIST_OF_REQUESTS_NEW,
      url: `${process.env.apiUrl}v0/search/listOfRequestsNewSitePi`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getNewListOfRequestsDataResetAction = () => ({
  type: LIST_OF_REQUESTS_NEW_RESET,
})

export function getNewListOfRequestsDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LIST_OF_REQUESTS_NEW: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case LIST_OF_REQUESTS_NEW_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case LIST_OF_REQUESTS_NEW_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case LIST_OF_REQUESTS_NEW_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const deleteRequestedSiteAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: DELETE_NEW_ADDED_SITE,
      url: `${process.env.apiUrl}v0/search/deleteRequest?${stringify(payload.params)}`,
      method: 'DELETE',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: meta.status_flag === 'SITE' ? 'Site deleted successfully' : 'PI deleted successfully',
    hideNotification: false,
  })
}

export const deleteRequestedSiteResetAction = () => ({
  type: DELETE_NEW_ADDED_SITE_RESET,
})

export function deleteRequestedSiteReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_NEW_ADDED_SITE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_NEW_ADDED_SITE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case DELETE_NEW_ADDED_SITE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case DELETE_NEW_ADDED_SITE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
